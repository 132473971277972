import { render, staticRenderFns } from "./IP_ByServiceLine.vue?vue&type=template&id=28b54a9d&scoped=true&"
import script from "./IP_ByServiceLine.vue?vue&type=script&lang=js&"
export * from "./IP_ByServiceLine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b54a9d",
  null
  
)

export default component.exports