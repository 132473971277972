<template>
  <div :id="`${this._uid}_pieChart`">
  </div>
</template>

<script>
  // import DetailsPopOver from './DetailsPopOver'

  export default {
    name: 'pieChart',
    components: {

    },
    props: ['data', 'nameValue', 'yValue'],
    data: function () {
      return {
        highCharts: null,
        chartTitleStyle: {
          fontSize: '5px'
        },
        dataLabelsStyle: {
          color: 'black'
        }
      }
    },
    methods: {
      getSeries(stats) {
        let data = []
        if (stats) {
          data = stats.map(m => {
            return {
              name: m[this.nameValue] === '<Not Specified>' ? '&lt;Not Specified&gt;' : m[this.nameValue],
              y: m[this.yValue] * 100,
              // y: 25.0,
              color: m.color
            }
          })
        }
        return data
      },
      renderPieChart() {
        // const stats = this.reviewerStatistics.timeSheetsByConsultant
        // const drilldownStats = this.reviewerStatistics.timeSheetsByConsultantDrilldown
        // const [series, colors] = []
        const series = this.getSeries(this.data)
        this.highCharts.chart(`${this._uid}_pieChart`, {
          chart: {
            type: 'pie',
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0
            // height: '50%'
          },
          title: {
            // text: 'My Timesheet Tasks',
            text: null,
            style: this.chartTitleStyle
          },
          tooltip: {
            pointFormat: '<span style="color:{point.color}">●</span> {point.percentage:.1f}%'
          },
          plotOptions: {
            pie: {
              // allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
                // style: this.dataLabelsStyle,
                // format: '{point.name}: {point.percentage:.1f} %'
              },
              showInLegend: true
            }
          },
          credits: {
            enabled: false
          },
          series: [{
            colorByPoint: true,
            data: series
          }]
        })
      }
    },
    created: function () {
    },
    mounted: function () {
      import(/* webpackChunkName: "highcharts" */'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
      })
    },
    computed: {
    },
    watch: {
      data() {
        this.renderPieChart()
      },
      highCharts() {
        if (this.highCharts) {
          this.renderPieChart()
        }
      }
    }
  }
</script>

<style scoped>
  >>>.highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }

  >>>.highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }
</style>
