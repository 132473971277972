<template>
  <div>
    <div v-loading="loading || proReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
      <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
        <el-dropdown trigger="click" @command="handleTableCommands">
          <span class="el-dropdown-link">
            <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="buildTableCommands('download', `proRebillTable`)">Download</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table id="proRebillTable" ref="proRebillTable" :data="allData" empty-text="No Rebill Data Available" max-height="425" stripe :cell-class-name="cellClasses">
        <el-table-column prop="patientNumber" label="Pat. Num." sortable>
          <template slot-scope="scope">
            <a target="_blank" :href="`/reviews/pro/${scope.row.proRecordId}`">{{ scope.row.patientNumber }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="mrNumber" label="MR Num." sortable> </el-table-column>
        <el-table-column prop="payorName" label="Payor" sortable> </el-table-column>
        <el-table-column prop="dateOfService" label="Date of Serv." sortable>
          <template slot-scope="scope">
            {{ format(parseISO(scope.row.dateOfService), 'M/d/yyyy') }}
          </template>
        </el-table-column>
        <el-table-column prop="posDescription" label="Place of Serv." sortable> </el-table-column>
        <el-table-column prop="originalCpt" label="Orig. CPT" sortable> </el-table-column>
        <el-table-column prop="origWorkRvu" label="Orig. Work RVU" sortable> </el-table-column>
        <el-table-column prop="recommendedCpt" label="Rec. CPT" sortable>
          <template slot-scope="scope">
            <div :class="`${scope.row.deleteCode ? 'line-through' : ''}`">{{ scope.row.deleteCode ? scope.row.originalCpt : scope.row.recommendedCpt }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="recommendedWorkRvu" label="Rec. Work RVU" sortable> </el-table-column>
        <el-table-column prop="rvuDiff" label="RVU Difference" sortable>
          <template slot-scope="scope">
            <div :class="`${scope.row.rvuDiff < 0 ? 'negative' : ''}`">{{ scope.row.rvuDiff }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="reasonForChange" label="Reason for Change" sortable> </el-table-column>
      </el-table>
    </div>

    <!-- <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0"> No Rebill Data Available </div>
    </center> -->

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :proRecordIds="subProRecordIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%">
      <PROSummary v-if="summaryDialogVisible" :item="summaryRow"></PROSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import PROSummary from './PRO_Summary'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'PROByCoder',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['proRecordIds', 'dashboards', 'hideDetails', 'codeType', 'codeTypes'],
    components: {
      accountList,
      PROSummary
    },
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        allData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/proRebillLog', payload)
          this.allData = response.data
          if (this.codeType === 'All Code Types' && this.allData.length === 1) {
            this.allData[0].key = 'All Code Types'
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.cliName
        this.subProRecordIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'cliName' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleCommand(command) {
        this.$emit('showModal', command)
      },
      buildCommand(outerDashboardRow, selectedDashboard, key) {
        const command = {
          proRecordIds: outerDashboardRow.accountNumbers,
          component: selectedDashboard.component,
          title: outerDashboardRow.coder + ' - ' + selectedDashboard.name,
          codeType: key
        }
        return command
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['proReportDataLoading'])
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      proRecordIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .line-through {
    text-decoration: line-through;
  }
</style>