<template>
  <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 250px;">
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown trigger="click" @command="
          command => {
            this.$emit('handleTableCommands', { command: command, ref: 'hccTable', csvName: 'hccTable', headers: [], excludeHeaders: ['Details'] })
          }
        ">
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table id="hccTable" class="tableNoWrap" ref="hccTable" :data="tableData" empty-text="No HCC Data Available" max-height="425" stripe :summary-method="getSummaries"
      :cell-class-name="cellClasses" show-summary row-key="code">
      <el-table-column prop="code" label="HCC">
        <template slot-scope="scope">
          <div tag="hcc">
            <span v-for="(item, index) in convertHccs(scope.row.code)" v-bind:key="item">
              <span v-html="highlightValue(item, filterCriteria)"></span>
              <span v-if="index + 1 !== convertHccs(scope.row.code).length">&bull;</span>
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description" sortable>
        <template slot-scope="scope">
          <div tag="description" :title="scope.row.description" v-html="highlightValue(scope.row.description, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="numOfNoRec" label="No Recommendations" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.numOfNoRec, filterCriteria)" title="No Recommendations"></div>
        </template>
      </el-table-column>
      <el-table-column prop="numOfAdditions" label="Additions" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.numOfAdditions, filterCriteria)" title="Additions"></div>
        </template>
      </el-table-column>
      <el-table-column prop="numOfDeletions" label="Deletions" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.numOfDeletions, filterCriteria)" title="Deletions" class="details"></div>
        </template>
      </el-table-column>
      <el-table-column prop="hccCodes" label="Accuracy Rate" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(`${(scope.row.accuracyRate * 100).toFixed(2)}%`, filterCriteria)" :class="`hcc_hccCodes_${scope.$index} details`"></div>
        </template>
      </el-table-column>
      <el-table-column label="Details" width="120" prop="details" v-if="!hideDetails">
        <template slot-scope="scope">
          <el-button class="dx-button" @click.native.prevent="updateAcctListDx(scope.row)" plain round size="mini" icon="mdi mdi-view-dashboard-variant-outline"
            :title="`View Diagnosis Codes for HCC ${scope.row.description}`" circle>
          </el-button>
          <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList :header="detailsHeader" v-if="dialogVisible" :drgTableIds="subDrgTableIds"></accountList>
    </el-dialog>

    <el-dialog :title="detailsHeader" :visible.sync="dxDialogVisible" center width="75%">
      <IPDx v-if="dxDialogVisible" class="dashboard-drilldown" :hideDetails="true" :drgTableIds="subDrgTableIds" style="min-height: 300px;" :hcc="hcc"></IPDx>
    </el-dialog>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import IPDx from './IP_DxSummary'

  export default {
    name: 'IP_DxSummary',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds', 'hideDetails'],
    components: {
      accountList,
      IPDx
    },
    data: function () {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        dxDialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {},
        detailsHeader: '',
        hcc: {}
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipByHcc', payload)
          this.tableData = response.data.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            this.$refs.hccTable.doLayout()
            this.loading = false
          })
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.code
        this.subDrgTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      updateAcctListDx(row) {
        this.detailsHeader = `Dx Codes for ${row.description}, HCC V24`
        this.subDrgTableIds = row.accountNumbers
        this.hcc = {
          hcc: row.code,
          hccVersion: 'HCCV24'
        }
        this.dxDialogVisible = !this.dxDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      convertHccs(delimited) {
        if (delimited) {
          const retArray = delimited.split('-')
          return retArray
        } else {
          return []
        }
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading'])
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .dx-button {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
  }
</style>