<template>
  <div v-loading="loading || opReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <div v-for="group in allData" :key="group.key">
      <template v-if="!codeType || group.key === codeType">
        <h2>{{ group.key }}</h2>
        <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
          <el-dropdown trigger="click" @command="handleTableCommands">
            <span class="el-dropdown-link">
              <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="buildTableCommands('download', `ascServiceLineTable_${group.key}`)">Download</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table :id="`ascServiceLineTable_${group.key}`" :ref="`ascServiceLineTable_${group.key}`" :data="group.data" empty-text="No Service Line Data Available"
          max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary>
          <el-table-column prop="serviceLineName" label="Service Line" sortable>
            <template slot-scope="scope">
              <div :title="scope.row.serviceLineName" v-html="highlightValue(scope.row.serviceLineName, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="totalRecords" label="Total Records" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.totalRecords, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="totalCodes" label="Total Codes" sortable>
            <template slot="header">
              Total Codes
              <el-tooltip effect="dark" content="Combined accuracy rate for CM, CPT with Reimb., CPT without Reimb. and Modifier codes" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('Total Codes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.totalCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="cmCodes" label="ICD-10 CM Codes" sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('ICD-10 CM Codes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.cmCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="cptWithReimb" label="CPTs with Reimb." sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('cpt cptWithReimb', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.cptWithReimb * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="cptWithoutReimb" label="CPTs without Reimb." sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('cpt cptWithoutReimb', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.cptWithoutReimb * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="modCodes" label="Modifier Codes" sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('Modifier Codes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.modCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="ascFinancialImpact" label="Financial Impact" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(moneyFormatter(scope.row.ascFinancialImpact), filterCriteria)" :class="`${scope.row.ascFinancialImpact < 0 ? 'negative' : ''}`">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="details" label="Details" width="150" v-if="!hideDetails">
            <template slot-scope="scope" class="textAlignLeft">
              <el-dropdown size="mini" @command="handleCommand" style="margin-right: 5px;">
                <el-button plain round circle size="mini" icon="mdi mdi-view-dashboard-variant-outline" title="Drilldown"> </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in filteredDashboards" :key="item.name" :command="buildCommand(scope.row, item, group.key)">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button @click.native.prevent="showSummary(scope.row)" plain round size="mini" icon="mdi mdi-table-eye" title="Details" circle> </el-button>
              <el-button style="margin-left: 5px;" @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included"
                circle> </el-button>
              <el-button style="margin-left: 5px;" @click.native.prevent="createSpecificReport(scope.row, group.key)" plain round size="mini" icon="el-icon-document"
                title="Create Report" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0">No Facility Data Available</div>
    </center>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :ascTableIds="subAscTableIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%" title="ASC Basic Summary">
      <ASCSummary :ref="`ascSummary_${summaryRow.codeDesc} - ${summaryRow.serviceLineName}`" v-if="summaryDialogVisible" :item="summaryRow"
        :title="`${summaryRow.codeDesc} - ${summaryRow.serviceLineName}`" @handleTableCommands="handleTableCommandsModal"></ASCSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import ASCSummary from './ASC_Summary'

  export default {
    name: 'OPByServiceLine',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'dashboards', 'hideDetails', 'codeType', 'codeTypes'],
    components: {
      accountList,
      ASCSummary
    },
    data: function () {
      return {
        allData: [],
        dropDownGroups: [],
        dataResponse: null,
        selectedGrouping: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subAscTableIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opByServiceLine', payload)
          this.dataResponse = response.data
          this.dropDownGroups = response.data.groupList
          this.groupKey = response.data.groupKey
          this.allData = response.data.data
          if (this.codeType === 'All Code Types' && this.allData.length === 1) {
            this.allData[0].key = 'All Code Types'
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.serviceLineName
        this.subAscTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'serviceLineName') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      getTableData(response) {
        let data = []
        data = response.data
        console.log(data)
        if (this.filterCriteria.length) {
          data = this.filterList(data, this.filterCriteria)
        }
        if (this.selectedGrouping.length) {
          data = data.filter(x => {
            let val
            this.selectedGrouping.forEach(y => {
              if (x[this.groupKey].toString() === y.toString()) {
                val = x
              }
            })
            return val
          })
        }
        return data
      },
      filterGroupUpdate() {
        if (this.dataResponse) {
          this.dropDownGroups = this.dataResponse.groupList.filter(x => {
            if (this.facilityTableData.some(y => y[this.groupKey].toString() === x.key.toString())) {
              return x
            }
            return false
          })
        }
      },
      groupDropDownFilter(data, currentTable, key) {
        return data.filter(x => {
          if (currentTable.some(y => y[key].toString() === x.key.toString())) {
            return x
          }
          return false
        })
      },
      handleCommand(command) {
        this.$emit('showModal', command)
      },
      buildCommand(outerDashboardRow, selectedDashboard, key) {
        const command = {
          ascTableIds: outerDashboardRow.accountNumbers,
          component: selectedDashboard.component,
          title: outerDashboardRow.serviceLineName + ' - ' + selectedDashboard.name,
          codeType: key
        }
        return command
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      },
      createSpecificReport(row, key) {
        this.$emit('createSpecificReport', { row: row, title: `${row.serviceLineName} - ${key}` })
      },
      handleTableCommandsModal(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['opReportDataLoading']),
      facilityTableData() {
        if (this.dataResponse) {
          return this.getTableData(this.dataResponse)
        }
        return []
      },
      filteredDashboards() {
        const ascRows = this.dashboards.filter(a => a.type === 'asc')
        let ascDashboards = []
        ascRows.forEach(x => {
          ascDashboards = ascDashboards.concat(x.options)
        })
        return ascDashboards.filter(a => a.component !== this.$options.name)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    beforeDestroy() { },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>