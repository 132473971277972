<template>
  <div v-loading="loading || opReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <el-row>
      <el-col :span="16">
        <div class="el-table el-table--mini">
          <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
            <el-dropdown trigger="click" @command="handleTableCommands">
              <span class="el-dropdown-link">
                <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="buildTableCommands('download', `opFinding_dxSummary`)">Download</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <table ref="opFinding_dxSummary" class="totalsTable hiaTable">
            <thead>
              <tr>
                <th>Records Reviewed With Dx Codes</th>
                <th>Records With Dx Findings</th>
                <th>Records Without Dx Findings</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ recordTotals.totalRecords }}</td>
                <td>{{ recordTotals.recordsWithFindings }}</td>
                <td>{{ recordTotals.recordsWithoutFindings }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>

    <div v-for="codeType in codeTypesAndData" style="margin-top: 30px; margin-bottom:20px;" :key="codeType.codeDesc">
      <h3>{{ codeType.codeDesc }}</h3>
      <el-col :span="16">
        <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
          <el-dropdown trigger="click" @command="handleTableCommands">
            <span class="el-dropdown-link">
              <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="buildTableCommands('download', `opDxFinding_${codeType.codeDesc}`)">Download</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table :id="`opDxFinding_${codeType.codeDesc}`" class="tableNoWrap" :ref="`opDxFinding_${codeType.codeDesc}`" :data="codeType.data"
          empty-text="No Dx Data Available" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary row-key="code">
          <el-table-column prop="description" label="Description" sortable>
            <template slot-scope="scope">
              <span :style="`color: ${scope.row.color}; float: left; font-size: 18px; padding: 0px 5px 0px 0px;`">●</span>
              <div v-html="highlightValue(scope.row.description, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="numberOfCodes" label="Frequency" sortable>
            <template slot-scope="scope">
              <div tag="numberOfCodes" :title="scope.row.numberOfCodes" v-html="highlightValue(scope.row.numberOfCodes, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="percentOfTotal" :label="`% of ${codeType.codeDesc}`" sortable>
            <template slot-scope="scope">
              {{ formatPercentage(scope.row.percentOfTotal) }}
            </template>
          </el-table-column>
          <el-table-column label="Details" width="120" prop="details">
            <template slot-scope="scope">
              <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="8">
        <pieChart :data="codeType.data" :nameValue="'description'" :yValue="'percentOfTotal'"></pieChart>
      </el-col>
    </div>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList :header="detailsHeader" v-if="dialogVisible" :ascTableIds="subAscTableIds"></accountList>
    </el-dialog>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import pieChart from '../highcharts/pieChart'

  export default {
    name: 'AMB_FindingSummaryDX',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'codeTypes'],
    components: {
      accountList,
      pieChart
    },
    data: function () {
      return {
        tableData: [],
        recordTotals: {},
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subAscTableIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/opDxFindingSummary`, payload)
          this.tableData = response.data.largeStat.data
          this.recordTotals = response.data.recordTotals
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.description
        this.subAscTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['opReportDataLoading']),
      codeTypesAndData() {
        const codeTypeList = [...new Set(this.tableData.map(x => x.codeDesc))]
        const codeTypesAndData = []
        for (let i = 0; i < codeTypeList.length; i++) {
          codeTypesAndData.push({
            codeDesc: codeTypeList[i],
            data: this.tableData.filter(x => x.codeDesc === codeTypeList[i])
          })
        }

        return codeTypesAndData
      }
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }
</style>