<template>
  <div v-loading="loading || proReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <div>
      <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
        <el-dropdown trigger="click" @command="handleTableCommands">
          <span class="el-dropdown-link">
            <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="buildTableCommands('download', `proReasonTable`)">Download</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table id="proReasonTable" ref="proReasonTable" :data="tableData" empty-text="No Reason Data Available" max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary>
        <el-table-column prop="changeDesc" label="Description" sortable>
          <template slot-scope="scope">
            <div>{{ scope.row.changeDesc }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="numRecords" label="Total Records" sortable>
          <template slot-scope="scope">
            <div v-html="highlightValue(scope.row.numRecords, filterCriteria)" class="details"></div>
          </template>
        </el-table-column>
        <el-table-column prop="rvuDiff" label="RVU Impact" sortable>
          <template slot-scope="scope">
            <div v-html="highlightValue(scope.row.rvuDiff, filterCriteria)" :class="`${scope.row.rvuDiff < 0 ? 'negative' : ''}`"></div>
          </template>
        </el-table-column>
        <el-table-column prop="details" label="Details" width="120" v-if="!hideDetails">
          <template slot-scope="scope" class="textAlignLeft">
            <!-- <el-button @click.native.prevent="showSummary(scope.row)" plain round size="mini" icon="mdi mdi-table-eye" title="Details" circle>
            </el-button> -->
            <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :proRecordIds="subProRecordIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%" title="Provider Basic Summary">
      <PROSummary :ref="`proSummary_${summaryRow.codeDesc} - ${summaryRow.changeDesc}`" v-if="summaryDialogVisible" :item="summaryRow" :title="`${summaryRow.codeDesc} - ${summaryRow.changeDesc}`" @handleTableCommands="handleTableCommandsModal"></PROSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import PROSummary from './PRO_Summary'

  export default {
    name: 'PROByReason',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['proRecordIds', 'hideDetails', 'codeTypes'],
    components: {
      accountList,
      PROSummary
    },
    data: function() {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false,
        summaryRow: {},
        detailsHeader: '',
        dialogVisible: false,
        summaryDialogVisible: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/proByHeaderReason', payload)
          this.tableData = response.data.data
          if (this.codeType === 'All Code Types' && this.allData.length === 1) {
            this.allData[0].key = 'All Code Types'
          }
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      buildAccountNumbers(title, row, e) {
        this.clearPopOverData()
        this.$nextTick(() => {
          this.tableType = 'accountNumbers'
          this.header = title
          this.popOverData = []
          this.row = row
          this.handleShowAccountNumbersPopOver(e.target)
        })
      },
      clearPopOverData() {
        this.CLEAR_DASHBOARD_ACCOUNTS()
        this.tableType = ''
        this.header = ''
        this.popOverData = []
        this.row = {}
        this.isOpen = false
      },
      handleShowPopOver(row) {
        const popper = this.$refs.ipDetailsPopOver
        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = document.querySelector(`.${row.table}_${row.prop}_${row.index}`)
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'changeDesc') {
          return 'textAlignLeft'
        }
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      updateAcctList(row) {
        this.detailsHeader = row.changeDesc
        this.subProRecordIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      },
      handleTableCommandsModal(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['proReportDataLoading'])
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    watch: {
      proRecordIds: async function() {
        await this.getData()
      },
      codeTypes: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
