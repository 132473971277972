<template>
  <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader">
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown
        trigger="click"
        @command="
          command => {
            this.$emit('handleTableCommands', { command: command, ref: 'pxTable', csvName: 'px_table', headers: [], excludeHeaders: ['Details'] })
          }
        "
      >
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table id="pxTable" class="tableNoWrap" ref="pxTable" :data="tableData" empty-text="No Px Data Available" max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary row-key="code">
      <el-table-column prop="code" label="Code" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.code, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="Description" sortable>
        <template slot-scope="scope">
          <div tag="description" :title="scope.row.description" v-html="highlightValue(scope.row.description, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="totalRecords" label="Occurrences" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.totalRecords, filterCriteria)" title="View Account Numbers" class="details"></div>
        </template>
      </el-table-column>
      <el-table-column prop="pcsCodes" label="Accuracy Rate" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('px pcsCodes', scope)">
            N/A
          </div>
          <div v-else v-html="highlightValue(`${(scope.row.pcsCodes * 100).toFixed(2)}%`, filterCriteria)" :class="`dx_cmCodes_${scope.$index} details`"></div>
        </template>
      </el-table-column>
      <el-table-column label="Details" width="120" prop="details" v-if="!hideDetails">
        <template slot-scope="scope">
          <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :drgTableIds="subDrgTableIds"></accountList>
    </el-dialog>

    <!-- <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close>
      <IPSummary v-if="summaryDialogVisible" :item="summaryRow"></IPSummary>
    </el-dialog> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'

  export default {
    name: 'IP_PxSummary',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds', 'hideDetails'],
    components: {
      accountList
    },
    data: function() {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipPx', payload)
          this.tableData = response.data.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            this.$refs.pxTable.doLayout()
            this.loading = false
          })
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.code
        this.subDrgTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      convertHccs(delimited) {
        if (delimited) {
          const retArray = delimited.split('-')
          return retArray
        } else {
          return []
        }
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading'])
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    watch: {
      drgTableIds: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
