<template>
  <div v-loading="loading || proReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <div v-for="group in allData" :key="group.key">
      <template v-if="!codeType || group.key === codeType">
        <h2>{{ group.key }}</h2>
        <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
          <el-dropdown trigger="click" @command="handleTableCommands">
            <span class="el-dropdown-link">
              <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="buildTableCommands('download', `hccTable_${group.key}`)">Download</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table :id="`hccTable_${group.key}`" class="tableNoWrap" :ref="`hccTable_${group.key}`" :data="group.data" empty-text="No HCC Data Available" max-height="425"
          stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary row-key="code">
          <el-table-column prop="code" label="HCC">
            <template slot-scope="scope">
              <div tag="hcc">
                <span v-for="(item, index) in convertHccs(scope.row.code)" v-bind:key="item">
                  <span v-html="highlightValue(item, filterCriteria)"></span>
                  <span v-if="index + 1 !== convertHccs(scope.row.code).length">&bull;</span>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
            <template slot-scope="scope">
              <div tag="description" :title="scope.row.description" v-html="highlightValue(scope.row.description, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="numOfNoRec" label="No Recommendations" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.numOfNoRec, filterCriteria)" title="No Recommendations"></div>
            </template>
          </el-table-column>
          <el-table-column prop="numOfAdditions" label="Additions" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.numOfAdditions, filterCriteria)" title="Additions"></div>
            </template>
          </el-table-column>
          <el-table-column prop="numOfDeletions" label="Deletions" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.numOfDeletions, filterCriteria)" title="Deletions" class="details"></div>
            </template>
          </el-table-column>
          <el-table-column prop="hccCodes" label="Accuracy Rate" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(`${(scope.row.accuracyRate * 100).toFixed(2)}%`, filterCriteria)" :class="`hcc_hccCodes_${scope.$index} details`"></div>
            </template>
          </el-table-column>
          <el-table-column label="Details" width="120" prop="details" v-if="!hideDetails">
            <template slot-scope="scope">
              <el-button class="dx-button" @click.native.prevent="updateAcctListDx(scope.row)" plain round size="mini" icon="mdi mdi-view-dashboard-variant-outline"
                :title="`View Diagnosis Codes for HCC ${scope.row.description}`" circle>
              </el-button>
              <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0">No HCC Data Available</div>
    </center>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList :header="detailsHeader" v-if="dialogVisible" :ascTableIds="subAscTableIds"></accountList>
    </el-dialog>

    <el-dialog :title="detailsHeader" :visible.sync="dxDialogVisible" center width="75%">
      <OPDx v-if="dxDialogVisible" class="dashboard-drilldown" :hideDetails="true" :ascTableIds="subAscTableIds" style="min-height: 300px;" :hcc="hcc"></OPDx>
    </el-dialog>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import OPDx from './OP_DxSummary.vue'

  export default {
    name: 'ASC_HCCSummaryV28',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'hideDetails', 'codeType', 'codeTypes'],
    components: {
      accountList,
      OPDx
    },
    data: function () {
      return {
        allData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        dxDialogVisible: false,
        subAscTableIds: null,
        summaryRow: {},
        detailsHeader: '',
        hcc: {}
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opByHCCV28', payload)
          this.allData = response.data.data
          if (this.codeType === 'All Code Types' && !this.allData.some(x => x.key === this.codeType)) {
            const codes = this.getAllCodeTypes(this.allData, 'accuracyRate')
            this.allData = [{ key: 'All Code Types', data: codes }]
          }
          if (this.codeType && !this.allData.some(x => x.key === this.codeType)) {
            this.allData.push({ key: this.codeType, data: [] })
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.code
        this.subAscTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      updateAcctListDx(row) {
        this.detailsHeader = `Dx Codes for ${row.description}, HCC V28`
        this.subAscTableIds = row.accountNumbers
        this.hcc = {
          hcc: row.code,
          hccVersion: 'HCCV28',
          codeDesc: row.codeDesc
        }
        this.dxDialogVisible = !this.dxDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      convertHccs(delimited) {
        if (delimited) {
          const retArray = delimited.split('-')
          return retArray
        } else {
          return []
        }
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['proReportDataLoading'])
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      ascTableIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>