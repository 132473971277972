import Vue from 'vue'

const dashboardHelper = {
  methods: {
    formatPercentage(input) {
      if (input === null || isNaN(input)) {
        return 'N/A'
      }

      return `${(input * 100).toFixed(2)}%`
    },
    moneyFormatter(value) {
      return `$${this.formatMoney(value)}`
    },
    getSummary(param) {
      const { columns, data } = param
      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          this.$nextTick(() => {
            const td = document.querySelector(`.el-table__footer .${column.id}`)
            if (td) {
              td.classList.add('textAlignLeft')
            }
          })
          sums[index] = 'Total'
          return
        }

        switch (column.property) {
          case 'coder':
            sums[index] = ''
            break
          case 'tags':
            sums[index] = ''
            break
          case 'startDate':
            sums[index] = ''
            break
          case 'cdiStaff':
            sums[index] = ''
            break
          case 'orDrg':
            sums[index] = ''
            break
          case 'patTypeDesc':
            sums[index] = ''
            break
          case 'changeReason':
            sums[index] = ''
            break
          case 'cliName':
            sums[index] = ''
            break
          case 'changeDesc':
            sums[index] = ''
            break
          case 'ipReviewType':
            sums[index] = ''
            break
          case 'details':
            sums[index] = ''
            break
          case 'isAHacCode':
            sums[index] = ''
            break
          case 'yes':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'no':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'na':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'notAnswered':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'totalRecords':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'numRecords':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'totalReviewed':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'number':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'numberOfChanges':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'numberOfCodes':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'noRec':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'additions':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'deletions':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'numOfNoRec':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'numOfAdditions':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'numOfDeletions':
            sums[index] = this.totalSummary(column.property, data, index)
            break
          case 'accuracy':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'totalDRGs':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'totalDRGNoQuery':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'totalCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'cmCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'emCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'iiCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'siCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'cptCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'cptWithReimb':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'cptWithoutReimb':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'modCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'pcsCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'patStatCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'pooCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'poaCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'apcCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'apcCodeswoQuery':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'hccCodes':
            sums[index] = this.totalPercentSummaries(column.property, data, index, column)
            break
          case 'amt': {
            const value = this.totalMoneySummary(column.property, data, index)
            const td = document.querySelector(`.el-table__footer .${column.id}`)
            if (td) {
              if (value.replace(/[$]|[,]/g, '') < 0) {
                td.classList.add('negative_td')
              } else {
                td.classList.remove('negative_td')
              }
            }

            sums[index] = value
            break
          }

          case 'reimbDiff': {
            const value = this.totalMoneySummary(column.property, data, index)
            const td = document.querySelector(`.el-table__footer .${column.id}`)
            if (td) {
              if (value.replace(/[$]|[,]/g, '') < 0) {
                td.classList.add('negative_td')
              } else {
                td.classList.remove('negative_td')
              }
            }

            sums[index] = value
            break
          }

          case 'financialImpact': {
            const value = this.totalMoneySummary(column.property, data, index)
            const td = document.querySelector(`.el-table__footer .${column.id}`)
            if (td) {
              if (value.replace(/[$]|[,]/g, '') < 0) {
                td.classList.add('negative_td')
              } else {
                td.classList.remove('negative_td')
              }
            }

            sums[index] = value
            break
          }

          case 'ascFinancialImpact': {
            const value = this.totalMoneySummary(column.property, data, index)
            const td = document.querySelector(`.el-table__footer .${column.id}`)
            if (td) {
              if (value.replace(/[$]|[,]/g, '') < 0) {
                td.classList.add('negative_td')
              } else {
                td.classList.remove('negative_td')
              }
            }

            sums[index] = value
            break
          }
          case 'code':
            sums[index] = ''
            break
          case 'description':
            sums[index] = ''
            break
          case 'hcc':
            sums[index] = ''
            break
          case 'rvuDiff': {
            const value = this.totalSummary(column.property, data, index)
            const roundedVal = value.toFixed(2)
            Vue.nextTick(() => {
              const td = document.querySelector(`.el-table__footer .${column.id}`)
              if (td) {
                if (roundedVal < 0) {
                  td.classList.add('negative_td')
                } else {
                  td.classList.remove('negative_td')
                }
              }
            })
            sums[index] = roundedVal
            break
          }
          default:
            sums[index] = 'N/A'
        }
      })

      return sums
    },
    totalPercentSummaries(column, data, index, columnArray) {
      let mt = 0
      let adr = 0
      for (let i = 0; i < data.length; i++) {
        // hccCodes summary
        if (column === 'hccCodes') {
          if (!isNaN(Number(data[i].numOfNoRec)) || !isNaN(Number(data[i].numOfAdditions)) || !isNaN(Number(data[i].numOfDeletions))) {
            mt += data[i].numOfNoRec + data[i].numOfAdditions + data[i].numOfDeletions // + data[i].revisedFromCount
          }

          if (!isNaN(Number(data[i].numOfAdditions)) || !isNaN(Number(data[i].numOfDeletions))) {
            adr += data[i].numOfAdditions + data[i].numOfDeletions
          }
        }

        // totalDRGs Summary
        if (column === 'totalDRGs') {
          if (!isNaN(Number(data[i].totalRecords))) {
            mt += data[i].totalRecords
          }

          if (!isNaN(Number(data[i].drgChanges))) {
            adr += data[i].drgChanges
          }
        }

        // totalDRGNoQuery Summary
        if (column === 'totalDRGNoQuery') {
          if (!isNaN(Number(data[i].totalRecords))) {
            mt += data[i].totalRecords
          }

          if (!isNaN(Number(data[i].asIsDRGChanges))) {
            adr += data[i].asIsDRGChanges
          }
        }

        // totalCodes Summary
        if (column === 'totalCodes') {
          if (!isNaN(Number(data[i].totalCodesAdditions)) || !isNaN(Number(data[i].totalCodesDeletions)) || !isNaN(Number(data[i].totalCodesNoRec)) || !isNaN(Number(data[i].totalCodesRev))) {
            mt += data[i].totalCodesAdditions + data[i].totalCodesDeletions + data[i].totalCodesNoRec + data[i].totalCodesRev
          }

          if (!isNaN(Number(data[i].totalCodesAdditions)) || !isNaN(Number(data[i].totalCodesDeletions)) || !isNaN(Number(data[i].totalCodesRev))) {
            adr += data[i].totalCodesAdditions + data[i].totalCodesDeletions + data[i].totalCodesRev
          }
        }

        // cmCodes Summary
        if (column === 'cmCodes') {
          if (!isNaN(Number(data[i].dxNoRec)) || !isNaN(Number(data[i].dxAdditions)) || !isNaN(Number(data[i].dxDeletions)) || !isNaN(Number(data[i].dxRevisions))) {
            mt += data[i].dxNoRec + data[i].dxAdditions + data[i].dxDeletions + data[i].dxRevisions
          }

          if (!isNaN(Number(data[i].dxAdditions)) || !isNaN(Number(data[i].dxDeletions)) || !isNaN(Number(data[i].dxRevisions))) {
            adr += data[i].dxAdditions + data[i].dxDeletions + data[i].dxRevisions
          }
        }

        if (column === 'emCodes') {
          if (!isNaN(Number(data[i].emNoRec)) || !isNaN(Number(data[i].emAdditions)) || !isNaN(Number(data[i].emDeletions)) || !isNaN(Number(data[i].emRevisions))) {
            mt += data[i].emNoRec + data[i].emAdditions + data[i].emDeletions + data[i].emRevisions
          }

          if (!isNaN(Number(data[i].emAdditions)) || !isNaN(Number(data[i].emDeletions)) || !isNaN(Number(data[i].emRevisions))) {
            adr += data[i].emAdditions + data[i].emDeletions + data[i].emRevisions
          }
        }

        if (column === 'iiCodes') {
          if (!isNaN(Number(data[i].iiNoRec)) || !isNaN(Number(data[i].iiAdditions)) || !isNaN(Number(data[i].iiDeletions)) || !isNaN(Number(data[i].iiRevisions))) {
            mt += data[i].iiNoRec + data[i].iiAdditions + data[i].iiDeletions + data[i].iiRevisions
          }

          if (!isNaN(Number(data[i].iiAdditions)) || !isNaN(Number(data[i].iiDeletions)) || !isNaN(Number(data[i].iiRevisions))) {
            adr += data[i].iiAdditions + data[i].iiDeletions + data[i].iiRevisions
          }
        }

        if (column === 'siCodes') {
          if (!isNaN(Number(data[i].siNoRec)) || !isNaN(Number(data[i].siAdditions)) || !isNaN(Number(data[i].siDeletions)) || !isNaN(Number(data[i].siRevisions))) {
            mt += data[i].siNoRec + data[i].siAdditions + data[i].siDeletions + data[i].siRevisions
          }

          if (!isNaN(Number(data[i].siAdditions)) || !isNaN(Number(data[i].siDeletions)) || !isNaN(Number(data[i].siRevisions))) {
            adr += data[i].siAdditions + data[i].siDeletions + data[i].siRevisions
          }
        }

        if (column === 'cptCodes') {
          if (!isNaN(Number(data[i].cptNoRec)) || !isNaN(Number(data[i].cptAdditions)) || !isNaN(Number(data[i].cptDeletions)) || !isNaN(Number(data[i].cptRevisions))) {
            mt += data[i].cptNoRec + data[i].cptAdditions + data[i].cptDeletions + data[i].cptRevisions
          }

          if (!isNaN(Number(data[i].cptAdditions)) || !isNaN(Number(data[i].cptDeletions)) || !isNaN(Number(data[i].cptRevisions))) {
            adr += data[i].cptAdditions + data[i].cptDeletions + data[i].cptRevisions
          }
        }

        if (column === 'cptWithReimb') {
          if (!isNaN(Number(data[i].asc_numCptReimbNoRec)) || !isNaN(Number(data[i].asc_numCptReimbAdditions)) || !isNaN(Number(data[i].asc_numCptReimbDeletions)) || !isNaN(Number(data[i].asc_numCptReimbRevisions))) {
            mt += data[i].asc_numCptReimbNoRec + data[i].asc_numCptReimbAdditions + data[i].asc_numCptReimbDeletions + data[i].asc_numCptReimbRevisions
          }

          if (!isNaN(Number(data[i].asc_numCptReimbAdditions)) || !isNaN(Number(data[i].asc_numCptReimbDeletions)) || !isNaN(Number(data[i].asc_numCptReimbRevisions))) {
            adr += data[i].asc_numCptReimbAdditions + data[i].asc_numCptReimbDeletions + data[i].asc_numCptReimbRevisions
          }
        }

        if (column === 'cptWithoutReimb') {
          if (!isNaN(Number(data[i].asc_numCptNoReimbNoRec)) || !isNaN(Number(data[i].asc_numCptNoReimbAdditions)) || !isNaN(Number(data[i].asc_numCptNoReimbDeletions)) || !isNaN(Number(data[i].asc_numCptNoReimbRevisions))) {
            mt += data[i].asc_numCptNoReimbNoRec + data[i].asc_numCptNoReimbAdditions + data[i].asc_numCptNoReimbDeletions + data[i].asc_numCptNoReimbRevisions
          }

          if (!isNaN(Number(data[i].asc_numCptNoReimbAdditions)) || !isNaN(Number(data[i].asc_numCptNoReimbDeletions)) || !isNaN(Number(data[i].asc_numCptNoReimbRevisions))) {
            adr += data[i].asc_numCptNoReimbAdditions + data[i].asc_numCptNoReimbDeletions + data[i].asc_numCptNoReimbRevisions
          }
        }

        if (column === 'modCodes') {
          if (!isNaN(Number(data[i].modNoRec)) || !isNaN(Number(data[i].modAdditions)) || !isNaN(Number(data[i].modDeletions)) || !isNaN(Number(data[i].modRevisions))) {
            mt += data[i].modNoRec + data[i].modAdditions + data[i].modDeletions + data[i].modRevisions
          }

          if (!isNaN(Number(data[i].modAdditions)) || !isNaN(Number(data[i].modDeletions)) || !isNaN(Number(data[i].modRevisions))) {
            adr += data[i].modAdditions + data[i].modDeletions + data[i].modRevisions
          }
        }

        // pcsCodes Summary
        if (column === 'pcsCodes') {
          if (!isNaN(Number(data[i].pxNoRec)) || !isNaN(Number(data[i].pxAdditions)) || !isNaN(Number(data[i].pxDeletions)) || !isNaN(Number(data[i].pxRevisions))) {
            mt += data[i].pxNoRec + data[i].pxAdditions + data[i].pxDeletions + data[i].pxRevisions
          }

          if (!isNaN(Number(data[i].pxAdditions)) || !isNaN(Number(data[i].pxDeletions)) || !isNaN(Number(data[i].pxRevisions))) {
            adr += data[i].pxAdditions + data[i].pxDeletions + data[i].pxRevisions
          }
        }

        // apc Summary
        if (column === 'apcCodes') {
          if (!isNaN(Number(data[i].apcNoRec)) || !isNaN(Number(data[i].apcAdditions)) || !isNaN(Number(data[i].apcDeletions)) || !isNaN(Number(data[i].apcRevisions))) {
            mt += data[i].apcNoRec + data[i].apcAdditions + data[i].apcDeletions + data[i].apcRevisions
          }

          if (!isNaN(Number(data[i].apcAdditions)) || !isNaN(Number(data[i].apcDeletions)) || !isNaN(Number(data[i].apcRevisions))) {
            adr += data[i].apcAdditions + data[i].apcDeletions + data[i].apcRevisions
          }
        }

        if (column === 'apcCodeswoQuery') {
          if (!isNaN(Number(data[i].apcNoRec_woQuery)) || !isNaN(Number(data[i].apcAdditions_woQuery)) || !isNaN(Number(data[i].apcDeletions_woQuery)) || !isNaN(Number(data[i].apcRevisions_woQuery))) {
            mt += data[i].apcNoRec_woQuery + data[i].apcAdditions_woQuery + data[i].apcDeletions_woQuery + data[i].apcRevisions_woQuery
          }

          if (!isNaN(Number(data[i].apcAdditions_woQuery)) || !isNaN(Number(data[i].apcDeletions_woQuery)) || !isNaN(Number(data[i].apcRevisions_woQuery))) {
            adr += data[i].apcAdditions_woQuery + data[i].apcDeletions_woQuery + data[i].apcRevisions_woQuery
          }
        }

        // patStatCodes Summary
        if (column === 'patStatCodes') {
          if (!isNaN(Number(data[i].patStat))) {
            mt += data[i].patStat
          }

          if (!isNaN(Number(data[i].patStatChanges))) {
            adr += data[i].patStatChanges
          }
        }

        // pooCodes Summary
        if (column === 'pooCodes') {
          if (!isNaN(Number(data[i].poo))) {
            mt += data[i].poo
          }

          if (!isNaN(Number(data[i].pooChanges))) {
            adr += data[i].pooChanges
          }
        }

        // poaCodes Summary
        if (column === 'poaCodes') {
          if (!isNaN(Number(data[i].poaNoRecommendations)) || !isNaN(Number(data[i].poaRecommendations))) {
            mt += data[i].poaNoRecommendations + data[i].poaRecommendations
          }

          if (!isNaN(Number(data[i].poaRecommendations))) {
            adr += data[i].poaRecommendations
          }
        }

        // PSI table accuracy rate
        if (column === 'accuracy') {
          if (!isNaN(Number(data[i].noRec)) || !isNaN(Number(data[i].additions)) || !isNaN(Number(data[i].deletions))) {
            mt += data[i].noRec + data[i].additions + data[i].deletions
          }

          if (!isNaN(Number(data[i].additions)) || !isNaN(Number(data[i].deletions))) {
            adr += data[i].additions + data[i].deletions
          }
        }

        // dx table accuracy rate
        if (columnArray.label === 'Accuracy Rate' && column !== 'hccCodes') {
          if (!isNaN(Number(data[i].numOfNoRec)) || !isNaN(Number(data[i].numOfAdditions)) || !isNaN(Number(data[i].numOfDeletions)) || !isNaN(Number(data[i].numOfRevisions))) {
            mt += data[i].numOfNoRec + data[i].numOfAdditions + data[i].numOfDeletions + data[i].numOfRevisions
          }

          if (!isNaN(Number(data[i].numOfAdditions)) || !isNaN(Number(data[i].numOfDeletions)) || !isNaN(Number(data[i].numOfRevisions))) {
            adr += data[i].numOfAdditions + data[i].numOfDeletions + data[i].numOfRevisions
          }
        }

        // dx table poa accuracy rate
        if (columnArray.label === 'POA Accuracy Rate') {
          if (!isNaN(Number(data[i].numOfPoaNoRec)) || !isNaN(Number(data[i].numOfPoaRec))) {
            mt += data[i].numOfPoaNoRec + data[i].numOfPoaRec
          }

          if (!isNaN(Number(data[i].numOfPoaRec))) {
            adr += data[i].numOfPoaRec
          }
        }
      }
      return mt || adr ? this.calculatePercentages(mt, adr) : 'N/A'
    },
    totalSummary(column, data, index) {
      const response = data.reduce((total, curr) => {
        const value = Number(curr[column])
        if (!isNaN(value)) {
          return total + curr[column]
        } else {
          return total
        }
      }, 0)

      return response
    },
    totalHACSummary(column, data, index) {
      const response = data.reduce((total, curr) => {
        const value = curr[column]
        if (value) {
          return total + 1
        } else {
          return total
        }
      }, 0)

      return response
    },
    totalMoneySummary(column, data, index) {
      const response = data.reduce((total, curr) => {
        const value = Number(curr[column])
        if (!isNaN(value)) {
          return total + curr[column]
        } else {
          return total
        }
      }, 0)
      return this.moneyFormatter(response)
    },
    calculatePercentages(masterTotal, adrTotal) {
      const response = masterTotal === null || masterTotal === 0 ? 0 : 1 - adrTotal / masterTotal
      return `${(response * 100).toFixed(2)}%`
    },
    checkPercentValues(column, scope) {
      let response = false
      switch (column) {
        case 'Total DRGs':
          if (!scope.row.totalRecords && !scope.row.drgChanges && !scope.row.drgChanges) {
            response = true
          }
          break
        case 'Total Codes':
          if (!scope.row.dxNoRec && !scope.row.pxNoRec && !scope.row.dxAdditions && !scope.row.pxAdditions && !scope.row.dxDeletions && !scope.row.pxDeletions && !scope.row.dxRevisions && !scope.row.pxRevisions && !scope.row.emAdditions && !scope.row.emDeletions && !scope.row.emNoRec && !scope.row.emRevisions && !scope.row.cptAdditions && !scope.row.cptDeletions && !scope.row.cptNoRec && !scope.row.cptRevisions && !scope.row.modAdditions && !scope.row.modDeletions && !scope.row.modNoRec && !scope.row.modRevisions) {
            response = true
          }
          break
        case 'ICD-10 CM Codes':
          if (!scope.row.dxNoRec && !scope.row.dxAdditions && !scope.row.dxDeletions && !scope.row.dxRevisions) {
            response = true
          }
          break
        case 'ICD-10 PCS Codes':
          if (!scope.row.pxNoRec && !scope.row.pxAdditions && !scope.row.pxDeletions && !scope.row.pxRevisions) {
            response = true
          }
          break
        case 'Patient Status Codes':
          if (!scope.row.patStatChanges && !scope.row.patStat) {
            response = true
          }
          break
        case 'Point of Origin Codes':
          if (!scope.row.poo && !scope.row.pooChanges) {
            response = true
          }
          break
        case 'POA Indicators':
          if (!scope.row.poaNoRecommendations && !scope.row.poaRecommendations) {
            response = true
          }
          break
        case 'dx cmCodes':
          if (!scope.row.dxAdditions && !scope.row.dxDeletions && !scope.row.dxNoRec && !scope.row.dxRevisions) {
            response = true
          }
          break
        case 'dx poaCodes':
          if (!scope.row.numOfPoaRec && !scope.row.numOfPoaNoRec) {
            response = true
          }
          break
        case 'px pcsCodes':
          if (!scope.row.pxAdditions && !scope.row.pxDeletions && !scope.row.pxNoRec && !scope.row.pxRevisions) {
            response = true
          }
          break
        case 'em emCodes':
          if (!scope.row.emAdditions && !scope.row.emDeletions && !scope.row.emNoRec && !scope.row.emRevisions) {
            response = true
          }
          break
        case 'ii iiCodes':
          if (!scope.row.iiAdditions && !scope.row.iiDeletions && !scope.row.iiNoRec && !scope.row.iiRevisions) {
            response = true
          }
          break
        case 'si siCodes':
          if (!scope.row.siAdditions && !scope.row.siDeletions && !scope.row.siNoRec && !scope.row.siRevisions) {
            response = true
          }
          break
        case 'cpt cptCodes':
          if (!scope.row.cptAdditions && !scope.row.cptDeletions && !scope.row.cptNoRec && !scope.row.cptRevisions) {
            response = true
          }
          break
        case 'cpt cptWithReimb':
          if (!scope.row.asc_numCptReimbAdditions && !scope.row.asc_numCptReimbDeletions && !scope.row.asc_numCptReimbNoRec && !scope.row.asc_numCptReimbRevisions) {
            response = true
          }
          break
        case 'cpt cptWithoutReimb':
          if (!scope.row.asc_numCptNoReimbAdditions && !scope.row.asc_numCptNoReimbDeletions && !scope.row.asc_numCptNoReimbNoRec && !scope.row.asc_numCptNoReimbRevisions) {
            response = true
          }
          break
        case 'mod modCodes':
          if (!scope.row.modAdditions && !scope.row.modDeletions && !scope.row.modNoRec && !scope.row.modRevisions) {
            response = true
          }
          break
        case 'qmpsi accuracy':
          if (!scope.row.numOfAdditions && !scope.row.numOfDeletions && !scope.row.numOfNoRec) {
            response = true
          }
          break
      }

      return response
    },
    getAllCodeTypes(data, accuracyColKey) {
      let arr = []
      data.forEach(x => {
        arr = arr.concat(x.data)
      })
      const allCodeTypes = []
      arr.reduce((res, value) => {
        if (!res[value.code]) {
          res[value.code] = {
            code: value.code,
            hcc: value.hcc,
            description: value.description,
            cmCodes: 0,
            totalRecords: 0,
            numOfAdditions: 0,
            numOfDeletions: 0,
            numOfNoRec: 0,
            numOfRevisions: 0
          }
          allCodeTypes.push(res[value.code])
        }
        const row = res[value.code]
        row.numOfAdditions += value.numOfAdditions
        row.numOfDeletions += value.numOfDeletions
        row.numOfNoRec += value.numOfNoRec
        row.numOfRevisions += value.numOfRevisions
        row.totalRecords += value.totalRecords

        let mt = 1
        let adr = 1

        if (!isNaN(Number(row.numOfNoRec)) || !isNaN(Number(row.numOfAdditions)) || !isNaN(Number(row.numOfDeletions)) || !isNaN(Number(row.numOfRevisions))) {
          mt = row.numOfNoRec + row.numOfAdditions + row.numOfDeletions + row.numOfRevisions
        }

        if (!isNaN(Number(row.numOfAdditions)) || !isNaN(Number(row.numOfDeletions)) || !isNaN(Number(row.numOfRevisions))) {
          adr = row.numOfAdditions + row.numOfDeletions + row.numOfRevisions
        }
        row[accuracyColKey] = mt || adr ? 1 - adr / mt : null
        return res
      }, {})
      return allCodeTypes
    }
  }
}

export default dashboardHelper
