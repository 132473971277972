<template>
  <div v-loading="loading || opReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 425px;">
    <ASCSummary :ref="`ascSummary_${getTitle(item)}`" v-for="item in tableData" :title="getTitle(item)" :key="getTitle(item)" :item="item" @handleTableCommands="handleTableCommands"></ASCSummary>
    <center v-if="!loading">
      <div class="no-data-message" v-if="tableData.length === 0">No Summary Data Available</div>
    </center>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'

  import dashboardHelper from '@/mixins/dashboardHelper'
  import ASCSummary from './ASC_Summary'
  export default {
    name: 'ASC_BasicSummary',
    mixins: [hiaFilters, dashboardHelper],
    components: {
      ASCSummary
    },
    props: ['ascTableIds', 'codeTypes'],
    data: function() {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opBasicStat', payload)
          this.tableData = response.data
        } catch (err) {
          this.tableData = []
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      getTitle(item) {
        return item.patTypeDesc + ' - ' + item.codeDesc
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['opReportDataLoading'])
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    watch: {
      ascTableIds: async function() {
        await this.getData()
      },
      codeTypes: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
