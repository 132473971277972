<template>
  <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader">
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown
        trigger="click"
        @command="
          command => {
            this.$emit('handleTableCommands', { command: command, ref: 'reasonTable', csvName: 'reason_table', headers: [], excludeHeaders: ['Details'] })
          }
        "
      >
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table id="reasonTable" ref="reasonTable" :data="tableData" empty-text="No Reason Data Available" max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary>
      <!-- <el-table-column prop="changeReason" label="Reason" sortable>
        <template slot-scope="scope">
          <div :title="scope.row.changeReason" v-html="highlightValue(scope.row.changeReason ,filterCriteria)" :class="`reason_changeReason_${scope.$index} details`" @click="buildPivotDataStats('Reason Stats', scope.row.totalRecords, scope.row.reimbInc, scope.row.reimbDec, scope.row.recordsWithRecommendations,  {table: 'reason', prop: 'changeReason', index: scope.$index, row: scope.row})">
          </div>
          <div :title="scope.row.changeReason" v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'changeReason', false) }}
          </div>
          <div :title="scope.row.changeReason" v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'changeReason', true) }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="changeDesc" label="Description" sortable>
        <template slot-scope="scope">
          <div>{{ scope.row.changeDesc }}</div>
          <!-- <div v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'changeDesc', false) }}
          </div>
          <div v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'changeDesc', true) }}
          </div>-->
        </template>
      </el-table-column>

      <el-table-column prop="totalRecords" label="Total Records" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.totalRecords, filterCriteria)" class="details"></div>
          <!-- <div v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'totalRecords', false) }}
          </div>
          <div v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'totalRecords', true) }}
          </div> -->
        </template>
      </el-table-column>
      <!-- <el-table-column v-if="activateComparison" prop="numClients" label="# Clients">
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.numClients ,filterCriteria)"></div>
          <div v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'numClients', false) }}
          </div>
          <div v-if="activateComparison && comparisonAccounts.length">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'numClients', true) }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="reimbDiff" label="Reimbursement Diff" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(moneyFormatter(scope.row.reimbDiff), filterCriteria)" :class="`reason_reimbDiff_${scope.$index} details ${scope.row.reimbDiff < 0 ? 'negative' : ''}`"></div>
          <!-- <div v-if="activateComparison && comparisonAccounts.length" :class="getBedSizeValue('changeReason', scope.row.changeReason, 'reimbDiff', false).includes('-') ? 'negative' : ''">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'reimbDiff', false) }}
          </div>
          <div v-if="activateComparison && comparisonAccounts.length" :class="getBedSizeValue('changeReason', scope.row.changeReason, 'reimbDiff', true).includes('-') ? 'negative' : ''">
            {{ getBedSizeValue('changeReason', scope.row.changeReason, 'reimbDiff', true) }}
          </div> -->
        </template>
      </el-table-column>

      <el-table-column prop="details" label="Details" width="150" v-if="!hideDetails">
        <template slot-scope="scope" class="textAlignLeft">
          <el-dropdown size="mini" @command="handleCommand" style="margin-right: 5px;">
            <el-button plain round circle size="mini" icon="mdi mdi-view-dashboard-variant-outline" title="Drilldown"> </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in filteredDashboards" :key="item.name" :command="buildCommand(scope.row, item, null)">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button @click.native.prevent="showSummary(scope.row)" plain round size="mini" icon="mdi mdi-table-eye" title="Details" circle> </el-button>
          <el-button style="margin-left: 5px;" @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
          <el-button style="margin-left: 5px;" @click.native.prevent="createSpecificReport(scope.row)" plain round size="mini" icon="el-icon-document" title="Create Report" circle> </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :drgTableIds="subDrgTableIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%" :title="`${summaryRow.changeDesc} - Inpatient Basic Summary`">
      <IPSummary ref="ipSummary" v-if="summaryDialogVisible" :item="summaryRow" @handleTableCommands="handleTableCommands"></IPSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import IPSummary from './IP_Summary'

  export default {
    name: 'IPByReason',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds', 'dashboards', 'hideDetails', 'codeType'],
    components: {
      accountList,
      IPSummary
    },
    data: function() {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false,
        detailsHeader: '',
        subDrgTableIds: null,
        dialogVisible: false,
        summaryRow: {},
        summaryDialogVisible: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipByReason', payload)
          this.tableData = response.data.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.$nextTick(() => {
            this.$refs.reasonTable.doLayout()
            this.loading = false
          })
        }
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      buildAccountNumbers(title, row, e) {
        this.clearPopOverData()
        this.$nextTick(() => {
          this.tableType = 'accountNumbers'
          this.header = title
          this.popOverData = []
          this.row = row
          this.handleShowAccountNumbersPopOver(e.target)
        })
      },
      clearPopOverData() {
        this.CLEAR_DASHBOARD_ACCOUNTS()
        this.tableType = ''
        this.header = ''
        this.popOverData = []
        this.row = {}
        this.isOpen = false
      },
      handleShowPopOver(row) {
        const popper = this.$refs.ipDetailsPopOver
        popper.doDestroy()
        popper.doClose()

        this.$nextTick(() => {
          this.popperClickTarget = document.querySelector(`.${row.table}_${row.prop}_${row.index}`)
          popper.doDestroy()
          popper.referenceElm = this.popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'changeDesc') {
          return 'textAlignLeft'
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.changeDesc
        this.subDrgTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      handleCommand(command) {
        this.$emit('showModal', command)
      },
      buildCommand(outerDashboardRow, selectedDashboard, key) {
        const command = {
          drgTableIds: outerDashboardRow.accountNumbers,
          component: selectedDashboard.component,
          title: outerDashboardRow.changeDesc + ' - ' + selectedDashboard.name,
          codeType: key
        }
        return command
      },
      createSpecificReport(row) {
        this.$emit('createSpecificReport', { row: row, title: row.changeDesc })
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading']),
      filteredDashboards() {
        const ipRows = this.dashboards.filter(a => a.type === 'ip')
        let ipDashboards = []
        ipRows.forEach(x => {
          ipDashboards = ipDashboards.concat(x.options)
        })
        return ipDashboards.filter(a => a.component !== this.$options.name)
      }
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    watch: {
      drgTableIds: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
