<template>
  <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    <div style="float: left; text-align: left; margin: 0px 0px 10px 10px; font-weight: 400;">Account Numbers for {{ header }}</div>

    <el-table :data="acctList" height="280px" ref="ipList">
      <el-table-column prop="patientNumber" label="Patient Number">
        <template slot-scope="scope">
          <template v-if="activeAccountLink">
            <router-link v-if="ipList" v-bind:to="`/reviews/inpatient/${scope.row.drgtableId}`" target="_blank">{{ scope.row.patientNumber }} </router-link>
            <router-link v-if="proList" v-bind:to="`/reviews/pro/${scope.row.proRecordId}`" target="_blank">{{ scope.row.patientNumber }} </router-link>
            <router-link v-if="opList" v-bind:to="`/reviews/outpatient/${scope.row.ascTableID}`" target="_blank">{{ scope.row.patientNumber }} </router-link>
          </template>
          <template v-else>
            <span>{{ scope.row.patientNumber }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="payName" label="Payor">
        <!-- <template slot-scope="scope">
                <div v-html="highlightValue(scope.row.payName,filterCriteria)"></div>
              </template> -->
      </el-table-column>
      <el-table-column v-if="ipList" prop="dschDate" label="Discharge Date">
        <template slot-scope="scope">
          <div>
            {{ scope.row.dschDate ? formatDate(scope.row.dschDate) : '' }}
            <!-- {{ scope.row.dschDate ? scope.row.dschDate : '' }} -->
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="!ipList" prop="dateOfService" label="Date of Service">
        <template slot-scope="scope">
          <div>
            {{ scope.row.dateOfService ? formatDate(scope.row.dateOfService) : '' }}
            <!-- {{ scope.row.dschDate ? scope.row.dschDate : '' }} -->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="reasonForChange" label="Reason For Change">
        <!-- <template slot-scope="scope">
                <div v-html="highlightValue(scope.row.description,filterCriteria)"></div>
              </template> -->
      </el-table-column>
      <el-table-column v-if="proList" prop="providerName" label="Provider"> </el-table-column>
      <el-table-column v-if="ipList" prop="originalDrg" label="Facility DRG">
        <!-- <template slot-scope="scope">
                <div :title="scope.row.orDrgDesc" v-html="highlightValue(scope.row.orDrg,filterCriteria)"></div>
              </template> -->
      </el-table-column>
      <el-table-column v-if="ipList" prop="newDrg" label="Rec. DRG">
        <!-- <template slot-scope="scope">
                <div v-html="highlightValue(scope.row.neDrg,filterCriteria)"></div>
              </template> -->
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button v-if="ipList" @click="handleViewWorksheets(scope.row.drgtableId)" plain round><i class="mdi mdi-open-in-new tableIcon"></i>Worksheet </el-button>
          <el-button v-if="proList" @click="handleViewWorksheetsPro(scope.row.proRecordId)" plain round><i class="mdi mdi-open-in-new tableIcon"></i>Worksheet </el-button>
          <el-button v-if="opList" @click="handleViewWorksheetsOP(scope.row.ascTableID)" plain round><i class="mdi mdi-open-in-new tableIcon"></i>Worksheet </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import axios from '@/utilities/axios-global'
  import dateHelper from '@/mixins/date-helper'

  import { mapState } from 'vuex'
  export default {
    name: 'acctList',
    props: ['header', 'drgTableIds', 'proRecordIds', 'ascTableIds'],
    mixins: [dateHelper],
    data() {
      return {
        acctList: [],
        loading: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          proRecordIds: this.proRecordIds,
          ascTableIds: this.ascTableIds,
          includeAccts: true,
          statType: ''
        }
        try {
          this.loading = true
          let endpoint = '/reports/dashboard/accountDetailsIP'
          // console.log(this.proList)
          // console.log(this.opList)
          if (this.proList) {
            endpoint = '/reports/dashboard/accountDetailsPro'
          } else if (this.opList) {
            endpoint = '/reports/dashboard/accountDetailsOP'
          }
          const response = await axios.post(this.baseApiUrl + endpoint, payload)
          this.acctList = response.data
        } catch (err) {
          this.acctList = []
        } finally {
          this.loading = false
        }
      },
      handleViewWorksheets(drgTableId) {
        const cacheBuster = new Date().getTime()
        const worksheetPath = this.inpatientReviewer() && this.ipList ? `/reviews/reports/inpatient/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${drgTableId}` : `/my-projects/inpatient/worksheet/${drgTableId}?dt=${cacheBuster}&fullscreen=true`
        this.worksheetWindow = window.open(worksheetPath, 'codebook', 'height=850,width=1600')
      },
      handleViewWorksheetsPro(proRecordId) {
        const cacheBuster = new Date().getTime()
        const worksheetPath = this.providerReviewer() && this.proList ? `/reviews/reports/pro/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${proRecordId}` : `/my-projects/pro/worksheet/${proRecordId}?dt=${cacheBuster}&fullscreen=true`
        this.worksheetWindow = window.open(worksheetPath, 'codebook', 'height=850,width=1600')
      },
      handleViewWorksheetsOP(ascTableId) {
        const cacheBuster = new Date().getTime()
        const worksheetPath = (this.outpatientReviewer() && this.opList) || (this.ambulatoryReviewer() && this.opList) ? `/reviews/reports/outpatient/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${ascTableId}` : `/my-projects/outpatient/worksheet/${ascTableId}?dt=${cacheBuster}&fullscreen=true`
        this.worksheetWindow = window.open(worksheetPath, 'codebook', 'height=850,width=1600')
      }
    },
    created: async function() {
      await this.getData()
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ipList() {
        if (this.drgTableIds) {
          return true
        } else {
          return false
        }
      },
      proList() {
        if (this.proRecordIds) {
          return true
        } else {
          return false
        }
      },
      opList() {
        if (this.ascTableIds) {
          return true
        } else {
          return false
        }
      },
      activeAccountLink() {
        if ((this.inpatientReviewer() && this.ipList) || (this.outpatientReviewer() && this.opList) || (this.ambulatoryReviewer() && this.opList) || (this.providerReviewer() && this.proList)) {
          return true
        }
        return false
      }
    }
  }
</script>

<style scoped>
  .disable-link {
    pointer-events: none;
  }
</style>
