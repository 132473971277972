<template>
  <div v-loading="loading || opReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <!-- <el-row>
      <el-col :span="6">
        <div ref="filterGroup">
          <el-select :disabled="!dropDownGroups.length" :default-first-option="true" v-model="selectedGrouping" placeholder="Filter Group" multiple filterable clearable collapse-tags>
            <el-option v-for="item in dropDownGroups" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div ref="globalSearch">
          <el-input prefix-icon="el-icon-search" size="mini" placeholder="Search Query.." type="text" class="globalSearch" v-model="filterCriteria" @keyup.native="filterGroupUpdate"></el-input>
        </div>
      </el-col>
    </el-row> -->

    <div v-for="group in allData" :key="group.key">
      <template v-if="!codeType || group.key === codeType">
        <h2>{{ group.key }}</h2>
        <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
          <el-dropdown trigger="click" @command="handleTableCommands">
            <span class="el-dropdown-link">
              <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="buildTableCommands('download', `opreasonTable_${group.key}`)">Download</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table :id="`opreasonTable_${group.key}`" :ref="`opreasonTable_${group.key}`" :data="group.data" empty-text="No Reason Data Available" max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary>
          <el-table-column prop="description" label="Reason" sortable>
            <template slot-scope="scope">
              <div :title="scope.row.description" v-html="highlightValue(scope.row.description, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="Total Records" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.number, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="amt" label="Financial Impact" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(moneyFormatter(scope.row.amt), filterCriteria)" :class="`${scope.row.amt < 0 ? 'negative' : ''}`"></div>
            </template>
          </el-table-column>
          <el-table-column prop="details" label="Details" width="120" v-if="!hideDetails">
            <template slot-scope="scope" class="textAlignLeft">
              <!-- <el-button @click.native.prevent="showSummary(scope.row)" plain round size="mini" icon="mdi mdi-table-eye" title="Details" circle>
            </el-button> -->
              <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0">No APC Reason Data Available</div>
    </center>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :ascTableIds="subAscTableIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%">
      <OPSummary v-if="summaryDialogVisible" :item="summaryRow"></OPSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import OPSummary from './OP_Summary'

  export default {
    name: 'OP_ByReason',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'hideDetails', 'codeType', 'codeTypes'],
    components: {
      accountList,
      OPSummary
    },
    data: function() {
      return {
        allData: [],
        dropDownGroups: [],
        dataResponse: null,
        selectedGrouping: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subAscTableIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opByReason', payload)
          this.dataResponse = response.data
          this.dropDownGroups = response.data.groupList
          this.groupKey = response.data.groupKey
          this.allData = response.data.data
          if (this.codeType && !this.allData.some(x => x.key === this.codeType)) {
            this.allData.push({ key: this.codeType, data: [] })
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.description
        this.subAscTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'description') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      getTableData(response) {
        let data = []
        data = response.data.data
        if (this.filterCriteria.length) {
          data = this.filterList(data, this.filterCriteria)
        }
        if (this.selectedGrouping.length) {
          data = data.filter(x => {
            let val
            this.selectedGrouping.forEach(y => {
              if (x[this.groupKey].toString() === y.toString()) {
                val = x
              }
            })
            return val
          })
        }
        console.log(data)
        return data
      },
      filterGroupUpdate() {
        if (this.dataResponse) {
          this.dropDownGroups = this.dataResponse.groupList.filter(x => {
            if (this.patTypeTableData.some(y => y[this.groupKey].toString() === x.key.toString())) {
              return x
            }
            return false
          })
        }
      },
      groupDropDownFilter(data, currentTable, key) {
        return data.filter(x => {
          if (currentTable.some(y => y[key].toString() === x.key.toString())) {
            return x
          }
          return false
        })
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['opReportDataLoading']),
      patTypeTableData() {
        if (this.dataResponse) {
          return this.getTableData(this.dataResponse)
        }
        return []
      }
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    beforeDestroy() {},
    watch: {
      ascTableIds: async function() {
        await this.getData()
      },
      codeTypes: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
