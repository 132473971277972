<template>
  <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader">
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown
        trigger="click"
        @command="
          command => {
            this.$emit('handleTableCommands', { command: command, ref: 'psiTable', csvName: 'psi_table', headers: [], excludeHeaders: ['Details'] })
          }
        "
      >
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table id="psiTable" ref="psiTable" :data="tableData" empty-text="No PSI Data Available" max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary>
      <el-table-column prop="shortDescription" label="Indicator" sortable>
        <template slot-scope="scope">
          <div class="textAlignLeft">
            {{ scope.row.shortDescription }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="longDescription" width="300" label="Description" sortable>
        <template slot-scope="scope">
          <div class="textAlignLeft">
            {{ scope.row.longDescription }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="totalRecords" label="Total Records" sortable> </el-table-column>
      <el-table-column prop="noRec" label="No Rec." sortable> </el-table-column>
      <el-table-column prop="additions" label="Additions" sortable> </el-table-column>
      <el-table-column prop="deletions" label="Deletions" sortable> </el-table-column>
      <el-table-column prop="accuracy" label="Accuracy" width="120">
        <template slot-scope="scope" class="textAlignLeft">
          {{ formatPercentage(scope.row.accuracy) }}
        </template>
      </el-table-column>

      <el-table-column label="Details" width="120" prop="details" v-if="!hideDetails">
        <template slot-scope="scope">
          <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :drgTableIds="subDrgTableIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%">
      <IPSummary ref="ipSummary" v-if="summaryDialogVisible" :item="summaryRow" @handleTableCommands="handleTableCommands"></IPSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import IPSummary from './IP_Summary'

  export default {
    name: 'IP_ByFacility',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds', 'hideDetails'],
    components: {
      accountList,
      IPSummary
    },
    data: function() {
      return {
        dropDownGroups: [],
        dataResponse: null,
        selectedGrouping: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {},
        detailsHeader: '',
        tableData: []
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipPsiSummary', payload)
          this.tableData = response.data
        } catch (err) {
        } finally {
          this.$nextTick(() => {
            this.$refs.psiTable.doLayout()
            this.loading = false
          })
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.shortDescription
        this.subDrgTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'shortDescription') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      getTableData(response) {
        let data = []
        data = response.data
        if (this.filterCriteria.length) {
          data = this.filterList(data, this.filterCriteria)
        }
        if (this.selectedGrouping.length) {
          data = data.filter(x => {
            let val
            this.selectedGrouping.forEach(y => {
              if (x[this.groupKey].toString() === y.toString()) {
                val = x
              }
            })
            return val
          })
        }
        return data
      },
      filterGroupUpdate() {
        if (this.dataResponse) {
          this.dropDownGroups = this.dataResponse.groupList.filter(x => {
            if (this.facilityTableData.some(y => y[this.groupKey].toString() === x.key.toString())) {
              return x
            }
            return false
          })
        }
      },
      groupDropDownFilter(data, currentTable, key) {
        return data.filter(x => {
          if (currentTable.some(y => y[key].toString() === x.key.toString())) {
            return x
          }
          return false
        })
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading']),
      facilityTableData() {
        if (this.dataResponse) {
          return this.getTableData(this.dataResponse)
        }
        return []
      }
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    beforeDestroy() {},
    watch: {
      drgTableIds: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
