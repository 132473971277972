<template>
  <div v-loading="loading || proReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">

    <template v-if="checklistsAndData.length === 0 && !loading">
      <center>No Checklist Data Available</center>
    </template>

    <div v-for="codeType in checklistsAndData" style="margin-top: 30px; margin-bottom:20px;" :key="codeType.codeDesc" class="codetype-wrapper">
      <h3>{{ codeType.codeDesc }}</h3>
      <el-row v-for="(checklist, index) in codeType.data" :key="index" class="checklist-wrapper" :gutter="20">
        <el-col :span="16">
          <el-col :span="24">
            <div class="el-table el-table--mini">
              <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
                <el-dropdown trigger="click" @command="handleTableCommands">
                  <span class="el-dropdown-link">
                    <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="buildTableCommands(checklist)">Download</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <table ref="proChecklistSummary" class="totalsTable hiaTable dashboard-table">
                <thead>
                  <tr>
                    <th>Checklist</th>
                    <th style="padding-right: 13px;">Records</th>
                    <th style="padding-right: 13px;">All Criteria Met</th>
                    <th style="padding-right: 13px;">Accuracy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="textAlignLeft">{{ checklist.checklistName }}</td>
                    <td class="textAlignRight">{{ checklist.totalRecords }}</td>
                    <td class="textAlignRight">{{ checklist.allCriteriaMet }}</td>
                    <td class="textAlignRight">{{ formatPercentage(checklist.accuracy) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>

          <el-col :span="24">
            <div style="margin-left: 20px; margin-top: 20px;">
              <el-table :id="`proChecklistSummary${checklist.checklistName}`" class="tableNoWrap subTable" :ref="`proChecklistSummary${checklist.checklistName}`"
                :data="checklist.checklistSummary" empty-text="No Checklist Data Available" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary
                row-key="code">
                <el-table-column prop="question" label="Item" sortable width="300">
                  <template slot-scope="scope">
                    <div v-html="highlightValue(scope.row.question, filterCriteria)"></div>
                  </template>
                </el-table-column>
                <el-table-column prop="yes" label="Yes" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex; justify-content: flex-end;">
                      <div>{{scope.row.yes}}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="no" label="No" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex; justify-content: flex-end;">
                      <div>{{scope.row.no}}</div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="na" label="N/A" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex; justify-content: flex-end;">
                      <div>{{scope.row.na}}</div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="notAnswered" label="Not Answered" sortable>
                  <template slot-scope="scope">
                    <div style="display: flex; justify-content: flex-end;">
                      <div>{{scope.row.notAnswered}}</div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Details" width="120" prop="details">
                  <template slot="header">
                    <div style="text-align: center;">Details</div>
                  </template>
                  <template slot-scope="scope">
                    <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </el-col>
        </el-col>

        <el-col :span="8" style="text-align: center; margin-top: 28px;">
          <div :id="`barchart${checklist.checklistId}${checklist.codeDesc}`"></div>
        </el-col>
      </el-row>
    </div>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <div style="min-height: 250px;">
        <div style="float: left; text-align: left; margin: 0px 0px 10px 0px; font-weight: 400;">
          Account Numbers for <i> Annual Wellness Visit - {{detailsHeader}}</i>
        </div>

        <v-client-table class="hiaTable" ref="managedListsGrid" id="managedListsGrid" :data="accountDetails"
          :columns="['patientNumber', 'payName', 'dateOfService', 'reasonForChange', 'providerName', 'answer', 'worksheet']" :options="tableOptions"
          v-loading="loadingAccounts" element-loading-spinner="atom-audit-loader">

          <template slot="h__answer">
            <div>{{detailsHeader}}</div>
          </template>
          <template slot="patientNumber" slot-scope="props">
            <template v-if="activeAccountLink">
              <router-link v-if="proList" v-bind:to="`/reviews/pro/${props.row.proRecordId}`" target="_blank">{{ props.row.patientNumber }} </router-link>
            </template>
            <template v-else>
              <span>{{ props.row.patientNumber }}</span>
            </template>
          </template>

          <template slot="dateOfService" slot-scope="props">
            {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
          </template>

          <template slot="worksheet" slot-scope="props">
            <el-button v-if="proList" @click="handleViewWorksheetsPro(props.row.proRecordId)" plain round><i class="mdi mdi-open-in-new tableIcon"></i>Worksheet </el-button>
          </template>
        </v-client-table>
      </div>

    </el-dialog>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import csvGenerator from '@/mixins/csvGenerator'
  import dateHelper from '@/mixins/date-helper'

  export default {
    name: 'PRO_FindingSummaryCPT',
    mixins: [hiaFilters, money, dashboardHelper, csvGenerator, dateHelper],
    props: ['proRecordIds', 'codeTypes'],
    components: {
    },
    data: function () {
      return {
        highCharts: null,
        checklistData: [],
        checklistTotals: {},
        filterCriteria: '',
        loading: false,
        loadingAccounts: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: '',
        accountDetails: [],
        tableOptions: {
          orderBy: {
            column: 'name',
            ascending: true
          },
          filterByColumn: true,
          headings: {
            patientNumber: 'Patient Number',
            payName: 'Payor',
            dateOfService: 'Date of Service',
            reasonForChange: 'Reason for Change',
            providerName: 'Provider'
          },
          columnsClasses: {
            name: 'smallColumn',
            description: 'wideColumn'
          },
          listColumns: {
            answer: [{ id: 'Yes', text: 'Yes' },
            { id: 'No', text: 'No' },
            { id: 'N/A', text: 'N/A' },
            { id: 'Not Answered', text: 'Not Answered' }]
          },
          perPage: 20,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/proChecklistSummary`, payload)
          this.checklistData = response.data
          await this.$nextTick()
          this.renderCharts()
        } catch (err) {
          this.checklistData = []
        } finally {
          this.loading = false
        }
      },
      renderCharts() {
        for (let i = 0; i < this.checklistData.length; i++) {
          const data = this.checklistData[i].checklistSummary
          // Extract questions and values for each category
          const questions = data.map(item => item.question)
          const yesData = data.map(item => item.yes)
          const noData = data.map(item => item.no)
          const naData = data.map(item => item.na)
          const notAnsweredData = data.map(item => item.notAnswered)

          const htmlId = `barchart${this.checklistData[i].checklistId}${this.checklistData[i].codeDesc}`

          const barHeight = 80
          const chartHeight = barHeight * questions.length

          this.highCharts.chart(htmlId, {
            chart: {
              type: 'bar',
              height: chartHeight > barHeight ? chartHeight : barHeight * 3
            },
            title: {
              text: this.checklistData[i].checklistName,
              style: {
                fontSize: '16px', // Font size,
                fontFamily: 'Roboto, sans-serif'
              }
            },
            xAxis: {
              categories: questions
            },
            yAxis: {
              min: 0
            },
            legend: {
              reversed: true
            },
            plotOptions: {
              bar: {
                cursor: 'pointer'
              },
              series: {
                // grouping: true, // Make bars grouped side by side
                stacking: 'normal',
                dataLabels: {
                  enabled: false,
                  formatter: function () {
                    if (this.y !== 0) {
                      return this.y
                    }
                  }
                }
              }
            },
            series: [
              { name: 'Not Answered', data: notAnsweredData, color: '#9E9E9E' },
              { name: 'N/A', data: naData, color: '#f9d25d' },
              { name: 'No', data: noData, color: '#f56c6c' },
              { name: 'Yes', data: yesData, color: '#7cbe5f' }
            ]
          })
        }
      },
      async updateAcctList(row, answer) {
        try {
          const payload = {
            itemId: row.itemId,
            accountNumbers: row.accountNumbers
          }

          this.loadingAccounts = true
          this.dialogVisible = !this.dialogVisible
          this.accountDetails = []
          await this.$nextTick()
          const response = await axios.post(`${this.baseApiUrl}/reports/dashboard/proChecklistSummary-AccountDetails`, payload)
          this.accountDetails = response.data
          this.loadingAccounts = false

          this.detailsHeader = `${row.question}`
          this.subProRecordIds = response.data.accountNumbers
        } catch (e) {
          this.$message({
            message: 'Error Getting Details',
            type: 'error'
          })
        }
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'question') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleViewWorksheetsPro(proRecordId) {
        const cacheBuster = new Date().getTime()
        const worksheetPath = this.providerReviewer() && this.proList ? `/reviews/reports/pro/worksheets?fullscreen=true&dt=${cacheBuster}&ids=${proRecordId}` : `/my-projects/pro/worksheet/${proRecordId}?dt=${cacheBuster}&fullscreen=true`
        this.worksheetWindow = window.open(worksheetPath, 'codebook', 'height=850,width=1600')
      },
      handleTableCommands(command) {
        const data = command.checklist

        let csvString = 'Code Type,Checklist Name,Total Records,All Criteria Met,Accuracy\n'

        csvString += `${data.codeDesc},${data.checklistName},${data.totalRecords},${data.allCriteriaMet},${data.accuracy}\n\n`

        csvString += 'Question,Yes,No,NA,Not Answered\n'

        data.checklistSummary.forEach(item => {
          csvString += `${item.question},${item.yes},${item.no},${item.na},${item.notAnswered}\n`
        })

        const blob = new Blob([csvString], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'checklist_summary.csv'
        link.click()
      },
      buildTableCommands(checklist) {
        const commandObject = {
          checklist: checklist
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['proReportDataLoading']),
      checklistsAndData() {
        const checklistList = [...new Set(this.checklistData.map(x => x.codeDesc))]
        const checklistsAndData = []
        for (let i = 0; i < checklistList.length; i++) {
          checklistsAndData.push({
            codeDesc: checklistList[i],
            data: this.checklistData.filter(x => x.codeDesc === checklistList[i])
          })
        }

        return checklistsAndData
      },
      proList() {
        if (this.proRecordIds) {
          return true
        } else {
          return false
        }
      },
      activeAccountLink() {
        if (this.providerReviewer() && this.proList) {
          return true
        }
        return false
      }
    },
    mounted: async function () {
      import(/* webpackChunkName: "highcharts" */'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
        this.getData()
      })
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      proRecordIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped>
  >>>.highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }

  >>>.highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }

  >>>.highcharts-credits {
    display: none;
  }

  .hiddenVisibility {
    visibility: hidden;
  }

  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }

  .checklist-wrapper:not(:first-child) {
    margin-top: 30px !important;
  }

  .codetype-wrapper:not(:first-child) {
    margin-top: 60px !important;
  }

  .totalsTable th:not(:first-child),
  .totalsTable td:not(:first-child) {
    text-align: right;
    margin-right: 5px;
  }

  .subTable>>>th:not(:first-child),
  .subTable>>>td:not(:first-child) {
    text-align: right;
    margin-right: 5px;
  }
</style>