<template>
  <div>
    <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
      <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
        <el-dropdown trigger="click" @command="handleTableCommands">
          <span class="el-dropdown-link">
            <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="buildTableCommands('download', `inpatientRebillTable`)">Download</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table id="inpatientRebillTable" ref="inpatientRebillTable" :data="allData" empty-text="No Rebill Data Available" max-height="425" stripe
        :cell-class-name="cellClasses" row-key="drgTableId">
        <!-- <el-table-column prop="reimbursementChangeType" label="Change Type" sortable>
        </el-table-column> -->
        <el-table-column key="patNum" prop="patNum" label="Pat. Num." sortable>
          <template slot-scope="scope">
            <a target="_blank" :href="`/reviews/inpatient/${scope.row.drgTableId}`">{{ scope.row.patNum }}</a>
          </template>
        </el-table-column>
        <el-table-column key="medRecNum" prop="medRecNum" label="MR Num." sortable> </el-table-column>
        <el-table-column key="dschDate" prop="dschDate" label="Discharge Date" sortable>
          <template slot-scope="scope">
            {{ format(parseISO(scope.row.dschDate), 'M/d/yyyy') }}
          </template>
        </el-table-column>

        <el-table-column key="payorName" prop="payorName" label="Payor" sortable> </el-table-column>
        <el-table-column key="coder" prop="coder" label="Coder" sortable> </el-table-column>
        <el-table-column key="drName" prop="drName" label="Provider" sortable> </el-table-column>
        <el-table-column key="serviceLineName" prop="serviceLineName" label="Service Line" sortable> </el-table-column>

        <template v-if="aprExists()">
          <el-table-column key="ipReviewType" prop="ipReviewType" label="DRG System" sortable> </el-table-column>
        </template>
        <el-table-column key="orDRG" prop="orDRG" label="Original DRG" sortable> </el-table-column>
        <el-table-column key="orDRGWt" prop="orDRGWt" label="Original Wt" sortable> </el-table-column>

        <template v-if="aprExists()">
          <el-table-column key="originalSOI" prop="originalSOI" label="Original SOI" sortable> </el-table-column>
          <el-table-column key="originalROM" prop="originalROM" label="Original ROM" sortable> </el-table-column>
        </template>

        <el-table-column key="originalReimb" prop="originalReimb" label="Original Reimb." sortable>
          <template slot-scope="scope">
            <div v-html="highlightValue(moneyFormatter(scope.row.originalReimb), filterCriteria)" :class="`${scope.row.originalReimb < 0 ? 'negative' : ''}`"></div>
          </template>
        </el-table-column>
        <el-table-column key="neDRG" prop="neDRG" label="Rec. DRG" sortable> </el-table-column>
        <el-table-column key="neDRGWt" prop="neDRGWt" label="Rec. Wt." sortable> </el-table-column>

        <template v-if="aprExists()">
          <el-table-column key="recommendedSOI" prop="recommendedSOI" label="Rec. SOI" sortable> </el-table-column>
          <el-table-column key="recommendedROM" prop="recommendedROM" label="Rec. ROM" sortable> </el-table-column>
        </template>

        <el-table-column key="newReimb" prop="newReimb" label="Rec. Reimb." sortable>
          <template slot-scope="scope">
            <div v-html="highlightValue(moneyFormatter(scope.row.newReimb), filterCriteria)" :class="`${scope.row.newReimb < 0 ? 'negative' : ''}`"></div>
          </template>
        </el-table-column>
        <el-table-column key="weightDifference" prop="weightDifference" label="Weight Difference" sortable>
          <template slot-scope="scope">
            <div :class="`${scope.row.weightDifference < 0 ? 'negative' : ''}`">{{ scope.row.weightDifference }}</div>
          </template>
        </el-table-column>
        <el-table-column key="financialImpact" prop="financialImpact" label="Financial Impact" sortable>
          <template slot-scope="scope">
            <div v-html="highlightValue(moneyFormatter(scope.row.financialImpact), filterCriteria)" :class="`${scope.row.financialImpact < 0 ? 'negative' : ''}`"></div>
          </template>
        </el-table-column>
        <el-table-column key="mdc" prop="mdc" label="MDC" sortable>
          <template slot-scope="scope">
            <div style="text-overflow: ellipsis; overflow: hidden;  white-space: nowrap;">
              {{ scope.row.mdc }}
            </div>
          </template>
        </el-table-column>
        <el-table-column key="rfcDescription" prop="rfcDescription" label="Reason for Change" sortable> </el-table-column>
      </el-table>
    </div>

    <!-- <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0"> No Rebill Data Available </div>
    </center> -->

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :drgTableIds="subProRecordIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%">
      <PROSummary v-if="summaryDialogVisible" :item="summaryRow"></PROSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import PROSummary from './PRO_Summary'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'IPRebillLog',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds', 'dashboards', 'hideDetails', 'codeType'],
    components: {
      accountList,
      PROSummary
    },
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        allData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/inpatientRebillLog', payload)
          this.allData = response.data
          if (this.codeType === 'All Code Types' && this.allData.length === 1) {
            this.allData[0].key = 'All Code Types'
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.cliName
        this.subProRecordIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'cliName' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleCommand(command) {
        this.$emit('showModal', command)
      },
      buildCommand(outerDashboardRow, selectedDashboard, key) {
        const command = {
          drgTableIds: outerDashboardRow.accountNumbers,
          component: selectedDashboard.component,
          title: outerDashboardRow.coder + ' - ' + selectedDashboard.name,
          codeType: key
        }
        return command
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      },
      aprExists() {
        const apr = this.allData.some(x => x.ipReviewType === 'APR-DRG')
        return apr
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading'])
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>