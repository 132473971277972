<template>
  <div v-loading="loading || opReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <div v-for="group in allData" :key="group.key">
      <template v-if="!codeType || group.key === codeType">
        <h2>{{ group.key }}</h2>
        <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
          <el-dropdown trigger="click" @command="handleTableCommands">
            <span class="el-dropdown-link">
              <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="buildTableCommands('download', `oppxTable_${group.key}`)">Download</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table :id="`oppxTable_${group.key}`" class="tableNoWrap" :ref="`oppxTable_${group.key}`" :data="group.data" empty-text="No Px Data Available" max-height="425" stripe :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary row-key="code">
          <el-table-column prop="code" label="Code" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.code, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="Description" sortable>
            <template slot-scope="scope">
              <div tag="description" :title="scope.row.description" v-html="highlightValue(scope.row.description, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="totalRecords" label="Occurrences" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.totalRecords, filterCriteria)" title="View Account Numbers" class="details"></div>
            </template>
          </el-table-column>
          <el-table-column prop="pcsCodes" label="Accuracy Rate" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(`${(scope.row.pcsCodes * 100).toFixed(2)}%`, filterCriteria)" :class="`op_pcsCodes_${scope.$index} details`"></div>
            </template>
          </el-table-column>
          <el-table-column label="Details" width="120" prop="details">
            <template slot-scope="scope">
              <el-button @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0">No Px Data Available</div>
    </center>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList :header="detailsHeader" v-if="dialogVisible" :ascTableIds="subProRecordIds"></accountList>
    </el-dialog>

    <!-- <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close>
      <IPSummary v-if="summaryDialogVisible" :item="summaryRow"></IPSummary>
    </el-dialog> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'

  export default {
    name: 'OP_PxSummary',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['ascTableIds', 'hideDetails', 'codeType', 'codeTypes'],
    components: {
      accountList
    },
    data: function() {
      return {
        allData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          ascTableIds: this.ascTableIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/opByPx', payload)
          this.allData = response.data.data
          if (this.codeType === 'All Code Types') {
            const codes = this.getAllCodeTypes(this.allData, 'pcsCodes')
            this.allData = [{ key: 'All Code Types', data: codes }]
          }
          if (this.codeType && !this.allData.some(x => x.key === this.codeType)) {
            this.allData.push({ key: this.codeType, data: [] })
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.code
        this.subProRecordIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      convertHccs(delimited) {
        if (delimited) {
          const retArray = delimited.split('-')
          return retArray
        } else {
          return []
        }
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'code' || column.property === 'description' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['opReportDataLoading'])
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    watch: {
      ascTableIds: async function() {
        await this.getData()
      },
      codeTypes: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
