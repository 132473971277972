<template>
  <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader">
    <!-- <el-row>
      <el-col :span="6">
        <div ref="filterGroup">
          <el-select :disabled="!dropDownGroups.length" :default-first-option="true" v-model="selectedGrouping" placeholder="Filter Group" multiple filterable clearable collapse-tags>
            <el-option v-for="item in dropDownGroups" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div ref="globalSearch">
          <el-input prefix-icon="el-icon-search" size="mini" placeholder="Search Query.." type="text" class="globalSearch" v-model="filterCriteria" @keyup.native="filterGroupUpdate"></el-input>
        </div>
      </el-col>
    </el-row> -->
    <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
      <el-dropdown trigger="click" @command="
          command => {
            this.$emit('handleTableCommands', { command: command, ref: 'projectTable', csvName: 'project_table', headers: [], excludeHeaders: ['Details'] })
          }
        ">
        <span class="el-dropdown-link">
          <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="download">Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table id="projectTable" ref="projectTable" :data="facilityTableData" empty-text="No Project Data Available" max-height="425" stripe :summary-method="getSummaries"
      :cell-class-name="cellClasses" show-summary>
      <el-table-column prop="projectName" label="Project" sortable>
        <template slot-scope="scope">
          <div class="textAlignLeft" :title="scope.row.projectName" v-html="highlightValue(scope.row.projectName, filterCriteria)"></div>
        </template>
      </el-table-column>

      <el-table-column prop="tags" label="Tags" sortable class-name="tags">
        <template slot-scope="scope">
          <div v-if="scope.row.tags">
            <div style="max-width: 100%" class="el-tag el-tag--mini el-tag--light" v-for="tag in scope.row.tags.split(',')" :key="tag">
              <span :title="tag" style="text-overflow: ellipsis; overflow: hidden; display: block;">
                {{ tag }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="startDate" label="Start Date" sortable>
        <template slot-scope="scope">
          {{ scope.row.startDate ? format(parseISO(scope.row.startDate), 'MM/dd/yyyy') : '' }}
        </template>
      </el-table-column>

      <el-table-column prop="totalRecords" label="Total Records" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(scope.row.totalRecords, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="totalDRGs" label="Total DRGs" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('Total DRGs', scope)" v-html="highlightValue('N/A', filterCriteria)"></div>
          <div v-else v-html="highlightValue(`${(scope.row.totalDRGs * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="totalDRGNoQuery" label="Total DRGs w/o Query" sortable>

        <template slot="header">
          Total DRGs w/o Query
          <el-tooltip effect="dark" content="Only includes DRG recommendations where Auditor Rec. Query Affects DRG is unchecked" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </template>

        <template slot-scope="scope">
          <div v-if="checkPercentValues('Total DRGs w/o Query', scope)" v-html="highlightValue('N/A', filterCriteria)"></div>
          <div v-else v-html="highlightValue(`${(scope.row.totalDRGNoQuery * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="totalCodes" label="Total Codes" sortable>

        <template slot="header">
          Total Codes
          <el-tooltip effect="dark" content="Combined accuracy rate for CM and PCS codes" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </template>

        <template slot-scope="scope">
          <div v-if="checkPercentValues('Total Codes', scope)">
            N/A
          </div>
          <div v-else v-html="highlightValue(`${(scope.row.totalCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="cmCodes" label="ICD-10 CM Codes" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('ICD-10 CM Codes', scope)">
            N/A
          </div>
          <div v-else v-html="highlightValue(`${(scope.row.cmCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="pcsCodes" label="ICD-10 PCS Codes" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('ICD-10 PCS Codes', scope)">
            N/A
          </div>
          <div v-else v-html="highlightValue(`${(scope.row.pcsCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="patStatCodes" label="Patient Status Codes" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('Patient Status Codes', scope)" v-html="highlightValue('N/A', filterCriteria)"></div>
          <div v-else v-html="highlightValue(`${(scope.row.patStatCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="pooCodes" label="Point of Origin Codes" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('Point of Origin Codes', scope)" v-html="highlightValue('N/A', filterCriteria)"></div>
          <div v-else v-html="highlightValue(`${(scope.row.pooCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="poaCodes" label="POA Indicators" sortable>
        <template slot-scope="scope">
          <div v-if="checkPercentValues('POA Indicators', scope)" v-html="highlightValue('N/A', filterCriteria)"></div>
          <div v-else v-html="highlightValue(`${(scope.row.poaCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="reimbDiff" label="Reimbursement Diff" sortable>
        <template slot-scope="scope">
          <div v-html="highlightValue(moneyFormatter(scope.row.reimbDiff), filterCriteria)" :class="`${scope.row.reimbDiff < 0 ? 'negative' : ''}`"></div>
        </template>
      </el-table-column>
      <el-table-column prop="details" label="Details" width="150" v-if="!hideDetails">
        <template slot-scope="scope" class="textAlignLeft">
          <el-dropdown size="mini" @command="handleCommand" style="margin-right: 5px;">
            <el-button plain round circle size="mini" icon="mdi mdi-view-dashboard-variant-outline" title="Drilldown"> </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in filteredDashboards" :key="item.name" :command="buildCommand(scope.row, item, null)">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button @click.native.prevent="showSummary(scope.row)" plain round size="mini" icon="mdi mdi-table-eye" title="Details" circle> </el-button>
          <el-button style="margin-left: 5px;" @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included"
            circle> </el-button>
          <el-button style="margin-left: 5px;" @click.native.prevent="createSpecificReport(scope.row)" plain round size="mini" icon="el-icon-document" title="Create Report"
            circle> </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :drgTableIds="subDrgTableIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%" :title="`${summaryRow.projectName} - Inpatient Basic Summary`">
      <IPSummary ref="ipSummary" v-if="summaryDialogVisible" :item="summaryRow" @handleTableCommands="handleTableCommands"></IPSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import IPSummary from './IP_Summary'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'IPByProject',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['drgTableIds', 'dashboards', 'hideDetails', 'codeType'],
    components: {
      accountList,
      IPSummary
    },
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        dropDownGroups: [],
        dataResponse: null,
        selectedGrouping: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subDrgTableIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipByProject', payload)
          this.dataResponse = response.data
          this.dropDownGroups = response.data.groupList
          this.groupKey = response.data.groupKey
        } catch (err) {
        } finally {
          this.$nextTick(() => {
            this.$refs.projectTable.doLayout()
            this.loading = false
          })
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.projectName
        this.subDrgTableIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'projectName') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      getTableData(response) {
        let data = []
        data = response.data
        if (this.filterCriteria.length) {
          data = this.filterList(data, this.filterCriteria)
        }
        if (this.selectedGrouping.length) {
          data = data.filter(x => {
            let val
            this.selectedGrouping.forEach(y => {
              if (x[this.groupKey].toString() === y.toString()) {
                val = x
              }
            })
            return val
          })
        }
        return data
      },
      filterGroupUpdate() {
        if (this.dataResponse) {
          this.dropDownGroups = this.dataResponse.groupList.filter(x => {
            if (this.facilityTableData.some(y => y[this.groupKey].toString() === x.key.toString())) {
              return x
            }
            return false
          })
        }
      },
      groupDropDownFilter(data, currentTable, key) {
        return data.filter(x => {
          if (currentTable.some(y => y[key].toString() === x.key.toString())) {
            return x
          }
          return false
        })
      },
      handleCommand(command) {
        this.$emit('showModal', command)
      },
      buildCommand(outerDashboardRow, selectedDashboard, key) {
        const command = {
          drgTableIds: outerDashboardRow.accountNumbers,
          component: selectedDashboard.component,
          title: outerDashboardRow.projectName + ' - ' + selectedDashboard.name,
          codeType: key
        }
        return command
      },
      createSpecificReport(row) {
        this.$emit('createSpecificReport', { row: row, title: row.projectName })
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading']),
      facilityTableData() {
        if (this.dataResponse) {
          return this.getTableData(this.dataResponse)
        }
        return []
      },
      filteredDashboards() {
        const ipRows = this.dashboards.filter(a => a.type === 'ip')
        let ipDashboards = []
        ipRows.forEach(x => {
          ipDashboards = ipDashboards.concat(x.options)
        })
        return ipDashboards.filter(a => a.component !== this.$options.name)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    beforeDestroy() { },
    watch: {
      drgTableIds: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>