<template>
  <div v-loading="loading || ipReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 425px;">
    <!-- <div slot="header" class="clearfix">
        <span>Aduit Results</span>
        <el-button style="float: right; padding: 3px 0" type="text">Operation button</el-button>
      </div> -->
    <!-- <div v-for="item in tableData" :key="item.patTypeDesc">
                <h1>{{ item.patTypeDesc }}</h1>
        <hr> -->
    <IPSummary ref="ipSummary" v-for="item in tableData" :title="item.patTypeDesc" :key="item.patTypeDesc" :item="item" @handleTableCommands="handleTableCommands"></IPSummary>
    <!-- </div> -->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'

  import dashboardHelper from '@/mixins/dashboardHelper'
  import IPSummary from './IP_Summary'
  export default {
    name: 'IP_BasicSummary',
    mixins: [hiaFilters, dashboardHelper],
    components: {
      IPSummary
    },
    props: ['drgTableIds'],
    data: function() {
      return {
        tableData: [],
        filterCriteria: '',
        loading: false
      }
    },
    methods: {
      async getData() {
        const payload = {
          drgTableIds: this.drgTableIds,
          includeAccts: false,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/ipBasicStat', payload)
          this.tableData = response.data
        } catch (err) {
          this.tableData = []
        } finally {
          this.loading = false
        }
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['ipReportDataLoading'])
    },
    mounted: async function() {
      await this.getData()
    },
    activated: async function() {
      await this.getData()
    },
    watch: {
      drgTableIds: async function() {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>
