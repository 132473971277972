<template>
  <div :id="`${this._uid}_barChart`">
  </div>
</template>

<script>
  // import DetailsPopOver from './DetailsPopOver'

  export default {
    name: 'barChart',
    components: {

    },
    props: ['data', 'nameValue', 'yValue', 'yTitle'],
    data: function () {
      return {
        highCharts: null,
        chartTitleStyle: {
          fontSize: '5px'
        },
        dataLabelsStyle: {
          color: 'black'
        }
      }
    },
    methods: {
      getOneToOneSeries(stats) {
        let data = []
        if (stats) {
          data = stats.map(m => {
            return {
              name: m[this.nameValue] === '<Not Specified>' ? '&lt;Not Specified&gt;' : m[this.nameValue],
              y: m[this.yValue],
              // y: 25.0,
              color: m.color
            }
          })
        }
        return data
      },
      renderOneToOneBarChart() {
        // const stats = this.reviewerStatistics.timeSheetsByConsultant
        // const drilldownStats = this.reviewerStatistics.timeSheetsByConsultantDrilldown
        // const [series, colors] = []
        const series = this.getOneToOneSeries(this.data)
        const categories = series.map(m => m.name)
        this.highCharts.chart(`${this._uid}_barChart`, {
          chart: {
            type: 'bar',
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0
            // width: 700
          },
          title: {
            // text: 'My Timesheet Tasks',
            text: null,
            style: this.chartTitleStyle
          },
          tooltip: {
            pointFormat: '<span style="color:{point.color}">●</span> {point.y}'
          },
          xAxis: {
            categories: categories,
            title: {
              text: null
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: this.yTitle
              // align: 'high'
            },
            labels: {
              overflow: 'justify'
            }
          },
          legend: {
            enabled: false,
            reversed: false,
            verticalAlign: 'top'
          },
          plotOptions: {
            bar: {
              // allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true
                // style: this.dataLabelsStyle,
                // format: '{point.name}: {point.percentage:.1f} %'
              }
              // showInLegend: true
            }
          },
          credits: {
            enabled: false
          },
          series: [{
            // colorByPoint: true,
            data: series
          }]
        })
      }
    },
    created: function () {

    },
    mounted: function () {
      import(/* webpackChunkName: "highcharts" */'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
      })
    },
    computed: {
    },
    watch: {
      data() {
        this.renderOneToOneBarChart()
      },
      highCharts() {
        if (this.highCharts) {
          this.renderOneToOneBarChart()
        }
      }
    }
  }
</script>

<style scoped>
  >>>.highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }

  >>>.highcharts-root {
    width: 100% !important;
    height: 100% !important;
  }
</style>
