<template>
  <div>
    <!-- <el-row :gutter="10">
      <el-col :span="20">
        <el-col :span="6">
          View Dashboard:
        </el-col>
        <el-col :span="18">
          <el-select id="dashboardSelect" v-model="selectedItem" placeholder="Select Dashboard" style="max-width: 40%; float: left">
            <el-option-group v-for="group in dashboardValues" :key="group.group" :label="group.group">
              <el-option v-for="item in group.options" :key="item.name" :label="item.name" :value="item.name">
              </el-option>
            </el-option-group>
          </el-select>
          <slot name="filters"></slot>
        </el-col>
      </el-col>
      <el-col :span="4">
        <el-button class="pull-right hiaButton" size="mini" icon="el-icon-document" plain round @click="createReport()">Create Report</el-button>
      </el-col>
    </el-row> -->
    <el-row>
      <el-col :span="5">
        <el-col :span="3">
          <i title="Select to View Dashboard" class="mdi mdi-apple-airplay" style="margin: 4px 0px 0px 10px; font-size: 18px;"></i>
        </el-col>
        <el-col :span="21">
          <el-select id="dashboardSelect" filterable v-model="selectedItem" placeholder="Select Dashboard" style="float: left; width: 100%;">
            <el-option-group v-for="group in dashboardValues" :key="group.group" :label="group.group">
              <el-option v-for="item in group.options" :key="item.name" :label="item.name" :value="item.name"> </el-option>
            </el-option-group>
          </el-select>
        </el-col>
      </el-col>
      <el-col :span="7">
        <slot name="filters"></slot>
      </el-col>
      <el-col :span="isReportSection ? 0 : 10">
        <slot name="stats"></slot>
      </el-col>
      <el-col :span="isReportSection ? 19 : 2">
        <el-button class="pull-right hiaButton" size="mini" icon="el-icon-document" plain round @click="createReport()">Create Report</el-button>
      </el-col>
    </el-row>
    <component ref="parentComponent" style="margin-top: 20px;" v-if="selectedItem" :is="getComponent(selectedItem)" :drgTableIds="drgTableIds" :proRecordIds="proRecordIds"
      :ascTableIds="ascTableIds" :dashboards="dashboards" :codeTypes="codeTypes" @showModal="showModal" @handleTableCommands="handleTableCommands"
      @createSpecificReport="createSpecificReport"> </component>
    <el-dialog id="drilldownDialog" :visible.sync="otherDashboardVisible" destroy-on-close width="75%" :title="drilldownParameter.title">
      <component v-if="otherDashboardVisible" key="dashboardComponent" class="dashboard-drilldown" :codeType="drilldownParameter.codeType" :hideDetails="true"
        ref="parentComponentOther" style="margin-top: 20px;" :is="drilldownParameter.component" :ascTableIds="drilldownParameter.ascTableIds"
        :drgTableIds="drilldownParameter.drgTableIds" :proRecordIds="drilldownParameter.proRecordIds" :dashboards="dashboards" @handleTableCommands="handleTableCommands">
      </component>
    </el-dialog>
  </div>
</template>

<script>
  import IPFindingSummaryWorksheet from './dashboards/IP_FindingSummaryWorksheet.vue'
  import IPFindingSummaryCombined from './dashboards/IP_FindingSummaryCombined.vue'
  import IPFindingSummaryDx from './dashboards/IP_FindingSummaryDx.vue'
  import IPFindingSummaryPx from './dashboards/IP_FindingSummaryPx.vue'
  import IPRebillLog from './dashboards/IP_RebillLog.vue'
  import IPByFacility from './dashboards/IP_ByFacility.vue'
  import IPByServiceLine from './dashboards/IP_ByServiceLine.vue'
  import IPByProject from './dashboards/IP_ByProject.vue'
  import IPByReason from './dashboards/IP_ByReason.vue'
  import IPBasicSummary from './dashboards/IP_BasicSummary.vue'
  import IPByCoder from './dashboards/IP_ByCoder.vue'
  import IPByCDIStaff from './dashboards/IP_ByCDIStaff.vue'
  import IPByDrg from './dashboards/IP_ByDrg.vue'
  import IPDx from './dashboards/IP_DxSummary.vue'
  import IPHCC from './dashboards/IP_HCCSummary.vue'
  import IPHCCV28 from './dashboards/IP_HCCSummaryV28.vue'
  import IPPx from './dashboards/IP_PxSummary.vue'
  import IPPsi from './dashboards/IP_ByPSI.vue'
  import IPQMMortality from './dashboards/IP_ByQMMortality.vue'
  import IPQMReadmission from './dashboards/IP_ByQMReadmission.vue'
  import IPDocAssessment from './dashboards/IP_DocAssessment.vue'
  import IPTrending from './dashboards/IP_Trending.vue'

  import PROBasicSummary from './dashboards/PRO_BasicSummary.vue'
  import PROByFacility from './dashboards/PRO_ByFacility.vue'
  import PROByServiceLine from './dashboards/PRO_ByServiceLine.vue'
  import PROByProject from './dashboards/PRO_ByProject.vue'
  import PROTrending from './dashboards/PRO_Trending.vue'
  import PROTrendingByCoder from './dashboards/PRO_TrendingByCoder.vue'
  import PROByProvider from './dashboards/PRO_ByProvider.vue'
  import PROByCoder from './dashboards/PRO_ByCoder.vue'
  import PROByReason from './dashboards/PRO_ByReason.vue'
  import PROByPatientType from './dashboards/PRO_ByPatientType.vue'
  import PRODx from './dashboards/PRO_DxSummary.vue'
  import PROHCC from './dashboards/PRO_HCCSummary.vue'
  import PROHCCV28 from './dashboards/PRO_HCCSummaryV28.vue'
  import PROCPT from './dashboards/PRO_CPTSummary.vue'
  import PROEM from './dashboards/PRO_EMSummary.vue'
  import PROMOD from './dashboards/PRO_ModSummary.vue'
  import PROFindingSummaryDX from './dashboards/PRO_FindingSummaryDX.vue'
  import PROFindingSummaryCPT from './dashboards/PRO_FindingSummaryCPT.vue'
  import PROFindingSummaryEM from './dashboards/PRO_FindingSummaryEM.vue'
  import PROFindingSummaryMod from './dashboards/PRO_FindingSummaryMod.vue'
  import PROFindingSummaryWorksheet from './dashboards/PRO_FindingSummaryWorksheet.vue'
  import PROFindingSummaryCombined from './dashboards/PRO_FindingSummaryCombined.vue'
  import PRORebillLog from './dashboards/PRO_RebillLog'
  import PROChecklistSummary from './dashboards/PRO_ChecklistSummary.vue'

  import OPFindingSummaryWorksheet from './dashboards/OP_FindingSummaryWorksheet.vue'
  import OPFindingSummaryCombined from './dashboards/OP_FindingSummaryCombined.vue'
  import OPFindingSummaryCPT from './dashboards/OP_FindingSummaryCPT.vue'
  import OPFindingSummaryDX from './dashboards/OP_FindingSummaryDX.vue'
  import OPFindingSummaryMod from './dashboards/OP_FindingSummaryMod.vue'
  import OPBasicSummary from './dashboards/OP_BasicSummary.vue'
  import OPByFacility from './dashboards/OP_ByFacility.vue'
  import OPByServiceLine from './dashboards/OP_ByServiceLine.vue'
  import OPByProject from './dashboards/OP_ByProject.vue'
  import OPByPatientType from './dashboards/OP_ByPatientType.vue'
  import OPByCoder from './dashboards/OP_ByCoder.vue'
  import OPDx from './dashboards/OP_DxSummary.vue'
  import OPPx from './dashboards/OP_PxSummary.vue'
  import OPCPT from './dashboards/OP_CPTSummary.vue'
  import OPMOD from './dashboards/OP_ModSummary.vue'
  import OPHCC from './dashboards/OP_HCCSummary.vue'
  import OPHCCV28 from './dashboards/OP_HCCSummaryV28.vue'
  import OPbyReason from './dashboards/OP_ByReason.vue'
  import OPTrending from './dashboards/OP_Trending.vue'

  import ASCFindingSummaryWorksheet from './dashboards/ASC_FindingSummaryWorksheet.vue'
  import ASCFindingSummaryCombined from './dashboards/ASC_FindingSummaryCombined.vue'
  import ASCFindingSummaryCPT from './dashboards/ASC_FindingSummaryCPT.vue'
  import ASCFindingSummaryDX from './dashboards/ASC_FindingSummaryDX.vue'
  import ASCFindingSummaryMod from './dashboards/ASC_FindingSummaryMod.vue'
  import ASCBasicSummary from './dashboards/ASC_BasicSummary.vue'
  import ASCByFacility from './dashboards/ASC_ByFacility.vue'
  import ASCByCoder from './dashboards/ASC_ByCoder.vue'
  import ASCByPatientType from './dashboards/ASC_ByPatientType.vue'
  import ASCByProject from './dashboards/ASC_ByProject.vue'
  import ASCByReason from './dashboards/ASC_ByReason.vue'
  import ASCByServiceLine from './dashboards/ASC_ByServiceLine.vue'
  import ASCDx from './dashboards/ASC_DxSummary.vue'
  import ASCCPTWithReimb from './dashboards/ASC_CPTWithReimbSummary.vue'
  import ASCCPTWithoutReimb from './dashboards/ASC_CPTWithoutReimbSummary.vue'
  import ASCHCC from './dashboards/ASC_HCCSummary.vue'
  import ASCHCCV28 from './dashboards/ASC_HCCSummaryV28.vue'
  import ASCMOD from './dashboards/ASC_ModSummary.vue'
  import ASCTrending from './dashboards/ASC_Trending.vue'

  import csvGenerator from '@/mixins/csvGenerator'

  export default {
    name: 'dashboards',
    components: {
      IPFindingSummaryWorksheet,
      IPFindingSummaryCombined,
      IPFindingSummaryDx,
      IPFindingSummaryPx,
      IPByFacility,
      IPRebillLog,
      IPByServiceLine,
      IPByProject,
      IPByReason,
      IPBasicSummary,
      IPByCoder,
      IPByCDIStaff,
      IPByDrg,
      IPDx,
      IPHCC,
      IPHCCV28,
      IPPx,
      IPPsi,
      IPTrending,
      IPQMMortality,
      IPQMReadmission,
      PROBasicSummary,
      PROByFacility,
      PROByServiceLine,
      PROByProvider,
      PROByCoder,
      PROByReason,
      PROByPatientType,
      PROTrending,
      PROTrendingByCoder,
      PRODx,
      PROHCC,
      PROHCCV28,
      PROCPT,
      PROEM,
      PROMOD,
      PROChecklistSummary,
      PROFindingSummaryDX,
      PROFindingSummaryCPT,
      PROFindingSummaryEM,
      PROFindingSummaryMod,
      PROFindingSummaryWorksheet,
      PROFindingSummaryCombined,
      PROByProject,
      PRORebillLog,
      OPFindingSummaryWorksheet,
      OPFindingSummaryCombined,
      OPFindingSummaryCPT,
      OPFindingSummaryDX,
      OPFindingSummaryMod,
      OPBasicSummary,
      OPByFacility,
      OPByServiceLine,
      OPByPatientType,
      OPByCoder,
      OPDx,
      OPPx,
      OPCPT,
      OPMOD,
      OPHCC,
      OPHCCV28,
      OPbyReason,
      OPByProject,
      OPTrending,
      ASCFindingSummaryWorksheet,
      ASCFindingSummaryCombined,
      ASCFindingSummaryCPT,
      ASCFindingSummaryDX,
      ASCFindingSummaryMod,
      ASCBasicSummary,
      ASCByFacility,
      ASCByCoder,
      ASCByPatientType,
      ASCByProject,
      ASCByReason,
      ASCByServiceLine,
      ASCDx,
      ASCCPTWithReimb,
      ASCCPTWithoutReimb,
      ASCHCC,
      ASCHCCV28,
      ASCMOD,
      IPDocAssessment,
      ASCTrending
    },
    props: ['drgTableIds', 'proRecordIds', 'ascTableIds', 'type', 'isReportSection', 'codeTypes', 'dropdowns'],
    mixins: [csvGenerator],
    data: function () {
      return {
        myHTML: '',
        selectedItem: null,
        activatedType: null,
        otherDashboardVisible: false,
        drilldownParameter: {},
        dashboards: [
          {
            group: 'Findings',
            type: 'ip',
            options: [
              {
                name: 'Inpatient Audit Findings Summary',
                component: 'IPFindingSummaryWorksheet',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Code Changes',
            type: 'ip',
            options: [
              {
                name: 'Inpatient Combined Change Summary',
                component: 'IPFindingSummaryCombined',
                filteredOnly: false
              },
              {
                name: 'Inpatient Dx Change Summary',
                component: 'IPFindingSummaryDx',
                filteredOnly: false
              },
              {
                name: 'Inpatient Px Change Summary',
                component: 'IPFindingSummaryPx',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Accuracy',
            type: 'ip',
            options: [
              {
                name: 'Inpatient Basic Summary',
                component: 'IPBasicSummary',
                filteredOnly: false
              },
              {
                name: 'IP By Facility',
                component: 'IPByFacility',
                filteredOnly: false
              },
              {
                name: 'IP By Project',
                component: 'IPByProject',
                filteredOnly: false
              },
              {
                name: 'IP By Service Line',
                component: 'IPByServiceLine',
                filteredOnly: false
              },
              {
                name: 'IP By Reason',
                component: 'IPByReason',
                filteredOnly: false
              },
              {
                name: 'IP By Coder',
                component: 'IPByCoder',
                filteredOnly: false
              },
              {
                name: 'IP Trending',
                component: 'IPTrending',
                filteredOnly: true
              },
              {
                name: 'IP By CDI Staff',
                component: 'IPByCDIStaff',
                filteredOnly: false
              },
              {
                name: 'IP By DRG',
                component: 'IPByDrg',
                filteredOnly: false
              },
              {
                name: 'IP Rebill Log',
                component: 'IPRebillLog',
                filteredOnly: false
              },
              {
                name: 'IP Dx Summary',
                component: 'IPDx',
                filteredOnly: false
              },
              {
                name: 'IP HCC V24 Summary',
                component: 'IPHCC',
                filteredOnly: false
              },
              {
                name: 'IP HCC V28 Summary',
                component: 'IPHCCV28',
                filteredOnly: false
              },
              {
                name: 'IP Px Summary',
                component: 'IPPx',
                filteredOnly: false
              },
              {
                name: 'IP Documentation Assessment',
                component: 'IPDocAssessment',
                filteredOnly: false
              },
              {
                name: 'Patient Safety Indicators',
                component: 'IPPsi',
                filteredOnly: false
              },
              {
                name: 'Mortality Quality Measures',
                component: 'IPQMMortality',
                filteredOnly: false
              },
              {
                name: 'Readmission Quality Measures',
                component: 'IPQMReadmission',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Findings',
            type: 'op',
            options: [
              {
                name: 'Outpatient Audit Findings Summary',
                component: 'OPFindingSummaryWorksheet',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Code Changes',
            type: 'op',
            options: [
              {
                name: 'Outpatient Combined Change Summary',
                component: 'OPFindingSummaryCombined',
                filteredOnly: false
              },
              {
                name: 'Outpatient Dx Change Summary',
                component: 'OPFindingSummaryDX',
                filteredOnly: false
              },
              {
                name: 'Outpatient CPT Change Summary',
                component: 'OPFindingSummaryCPT',
                filteredOnly: false
              },
              {
                name: 'Outpatient Modifier Change Summary',
                component: 'OPFindingSummaryMod',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Accuracy',
            type: 'op',
            options: [
              {
                name: 'Outpatient Basic Summary',
                component: 'OPBasicSummary',
                filteredOnly: false
              },
              {
                name: 'OP By Facility',
                component: 'OPByFacility',
                filteredOnly: false
              },
              {
                name: 'OP By Project',
                component: 'OPByProject',
                filteredOnly: false
              },
              {
                name: 'OP By Patient Type',
                component: 'OPByPatientType',
                filteredOnly: false
              },
              {
                name: 'OP By Coder',
                component: 'OPByCoder',
                filteredOnly: false
              },
              {
                name: 'OP Trending',
                component: 'OPTrending',
                filteredOnly: true
              },
              {
                name: 'OP By Service Line',
                component: 'OPByServiceLine',
                filteredOnly: false
              },
              {
                name: 'OP By APC Reason',
                component: 'OPbyReason',
                filteredOnly: false
              },
              {
                name: 'OP By Dx',
                component: 'OPDx',
                filteredOnly: false
              },
              {
                name: 'OP By HCC V24',
                component: 'OPHCC',
                filteredOnly: false
              },
              {
                name: 'OP By HCC V28',
                component: 'OPHCCV28',
                filteredOnly: false
              },
              {
                name: 'OP By Px',
                component: 'OPPx',
                filteredOnly: false
              },
              {
                name: 'OP By CPT',
                component: 'OPCPT',
                filteredOnly: false
              },
              {
                name: 'OP By Modifier',
                component: 'OPMOD',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Findings',
            type: 'asc',
            options: [
              {
                name: 'Ambulatory Audit Findings Summary',
                component: 'ASCFindingSummaryWorksheet',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Code Changes',
            type: 'asc',
            options: [
              {
                name: 'Ambulatory Combined Change Summary',
                component: 'ASCFindingSummaryCombined',
                filteredOnly: false
              },
              {
                name: 'Ambulatory Dx Change Summary',
                component: 'ASCFindingSummaryDX',
                filteredOnly: false
              },
              {
                name: 'Ambulatory CPT Change Summary',
                component: 'ASCFindingSummaryCPT',
                filteredOnly: false
              },
              {
                name: 'Ambulatory Modifier Change Summary',
                component: 'ASCFindingSummaryMod',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Accuracy',
            type: 'asc',
            options: [
              {
                name: 'ASC Basic Summary',
                component: 'ASCBasicSummary',
                filteredOnly: false
              },
              {
                name: 'ASC By Facility',
                component: 'ASCByFacility',
                filteredOnly: false
              },
              {
                name: 'ASC By Project',
                component: 'ASCByProject',
                filteredOnly: false
              },
              {
                name: 'ASC By Patient Type',
                component: 'ASCByPatientType',
                filteredOnly: false
              },
              {
                name: 'ASC By Coder',
                component: 'ASCByCoder',
                filteredOnly: false
              },
              {
                name: 'ASC Trending',
                component: 'ASCTrending',
                filteredOnly: true
              },
              {
                name: 'ASC By Service Line',
                component: 'ASCByServiceLine',
                filteredOnly: false
              },
              {
                name: 'ASC By Reason',
                component: 'ASCByReason',
                filteredOnly: false
              },
              {
                name: 'ASC By Dx',
                component: 'ASCDx',
                filteredOnly: false
              },
              {
                name: 'ASC By HCC V24',
                component: 'ASCHCC',
                filteredOnly: false
              },
              {
                name: 'ASC By HCC V28',
                component: 'ASCHCCV28',
                filteredOnly: false
              },
              {
                name: 'ASC By CPTs With Reimbursement',
                component: 'ASCCPTWithReimb',
                filteredOnly: false
              },
              {
                name: 'ASC By CPTs Without Reimbursement',
                component: 'ASCCPTWithoutReimb',
                filteredOnly: false
              },
              {
                name: 'ASC By Modifier',
                component: 'ASCMOD',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Findings',
            type: 'pro',
            options: [
              {
                name: 'Pro Audit Findings Summary',
                component: 'PROFindingSummaryWorksheet',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Code Changes',
            type: 'pro',
            options: [
              {
                name: 'Pro Combined Change Summary',
                component: 'PROFindingSummaryCombined',
                filteredOnly: false
              },
              {
                name: 'Pro Dx Change Summary',
                component: 'PROFindingSummaryDX',
                filteredOnly: false
              },
              {
                name: 'Pro CPT Change Summary',
                component: 'PROFindingSummaryCPT',
                filteredOnly: false
              },
              {
                name: 'Pro EM Change Summary',
                component: 'PROFindingSummaryEM',
                filteredOnly: false
              },
              {
                name: 'Pro Modifier Change Summary',
                component: 'PROFindingSummaryMod',
                filteredOnly: false
              }
            ]
          },
          {
            group: 'Accuracy',
            type: 'pro',
            options: [
              {
                name: 'Provider Basic Summary',
                component: 'PROBasicSummary',
                filteredOnly: false
              },
              {
                name: 'Pro By Coder',
                component: 'PROByCoder',
                filteredOnly: false
              },
              {
                name: 'Coder Trending',
                component: 'PROTrendingByCoder',
                filteredOnly: true
              },
              {
                name: 'Pro By Provider',
                component: 'PROByProvider',
                filteredOnly: false
              },
              {
                name: 'Provider Trending',
                component: 'PROTrending',
                filteredOnly: true
              },
              {
                name: 'Pro By Patient Type',
                component: 'PROByPatientType',
                filteredOnly: false
              },
              {
                name: 'Pro By Facility',
                component: 'PROByFacility',
                filteredOnly: false
              },
              {
                name: 'Pro By Project',
                component: 'PROByProject',
                filteredOnly: true
              },
              {
                name: 'Pro By Service Line',
                component: 'PROByServiceLine',
                filteredOnly: false
              },
              {
                name: 'Pro By Record Reason',
                component: 'PROByReason',
                filteredOnly: false
              },
              {
                name: 'Pro Fee Schedule Rebill Log',
                component: 'PRORebillLog',
                filteredOnly: false
              },
              {
                name: 'Pro By Dx',
                component: 'PRODx',
                filteredOnly: false
              },
              {
                name: 'Pro By HCC V24',
                component: 'PROHCC',
                filteredOnly: false
              },
              {
                name: 'Pro By HCC V28',
                component: 'PROHCCV28',
                filteredOnly: false
              },
              {
                name: 'Pro By CPT',
                component: 'PROCPT',
                filteredOnly: false
              },
              {
                name: 'Pro By E/M',
                component: 'PROEM',
                filteredOnly: false
              },
              {
                name: 'Pro By Modifier',
                component: 'PROMOD',
                filteredOnly: false
              },
              {
                name: 'Pro Checklist Summary',
                component: 'PROChecklistSummary',
                filteredOnly: false
              }
            ]
          }
        ]
      }
    },
    methods: {
      getComponent(name) {
        for (let i = 0; i < this.dashboards.length; i++) {
          const obj = this.dashboards[i].options.find(o => o.name === name)
          if (obj) {
            return obj.component
          }
        }
      },
      createReport() {
        const cacheBuster = new Date().getTime()
        if (this.proRecordIds && this.type === 'pro') {
          window.localStorage.setItem('reportIds', this.proRecordIds)
          if (!this.codeTypes) {
            window.localStorage.setItem('codeTypes', '')
          } else {
            window.localStorage.setItem('codeTypes', this.codeTypes)
          }
          window.localStorage.removeItem('reportTitle')
          window.localStorage.setItem('codeTypesDesc', this.getCodeTypeFilters())
          window.localStorage.setItem('isReportSection', this.isReportSection)
          this.worksheetWindow = window.open(`/reports/pro-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Pro Audit Report', 'height=850,width=1600')
        }

        if (this.drgTableIds && this.type === 'ip') {
          window.localStorage.setItem('reportIds', this.drgTableIds)
          window.localStorage.removeItem('reportTitle')
          window.localStorage.setItem('isReportSection', this.isReportSection)
          this.worksheetWindow = window.open(`/reports/inpatient-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Inpatient Audit Report', 'height=850,width=1600')
        }

        if (this.ascTableIds && this.type === 'op') {
          window.localStorage.setItem('reportIds', this.ascTableIds)
          if (!this.codeTypes) {
            window.localStorage.setItem('codeTypes', '')
          } else {
            window.localStorage.setItem('codeTypes', this.codeTypes)
          }
          window.localStorage.removeItem('reportTitle')
          window.localStorage.setItem('codeTypesDesc', this.getCodeTypeFilters())
          window.localStorage.setItem('isReportSection', this.isReportSection)
          this.worksheetWindow = window.open(`/reports/outpatient-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Outpatient Audit Report', 'height=850,width=1600')
        }

        if (this.ascTableIds && this.type === 'asc') {
          window.localStorage.setItem('reportIds', this.ascTableIds)
          if (!this.codeTypes) {
            window.localStorage.setItem('codeTypes', '')
          } else {
            window.localStorage.setItem('codeTypes', this.codeTypes)
          }
          window.localStorage.removeItem('reportTitle')
          window.localStorage.setItem('codeTypesDesc', this.getCodeTypeFilters())
          window.localStorage.setItem('isReportSection', this.isReportSection)
          this.worksheetWindow = window.open(`/reports/ambulatory-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Ambulatory Audit Report', 'height=850,width=1600')
        }
      },
      getCodeTypeFilters() {
        let codeTypeDesc = ''
        if (this.codeTypes) {
          for (let i = 0; i < this.codeTypes.length; i++) {
            // console.log(this.dropdowns)
            const ct = this.dropdowns.codeTypes.list.find(o => o.key === this.codeTypes[i])
            //   this.dropdowns
            if (codeTypeDesc) {
              codeTypeDesc = codeTypeDesc + ', ' + ct.value
            } else {
              codeTypeDesc = ct.value
            }
          }
        }

        return codeTypeDesc
      },
      defaultSelection() {
        if (this.type && this.activatedType !== this.type) {
          this.activatedType = this.type
          switch (this.type) {
            case 'ip':
              this.selectedItem = 'Inpatient Basic Summary'
              break
            case 'op':
              this.selectedItem = 'Outpatient Basic Summary'
              break
            case 'asc':
              this.selectedItem = 'ASC Basic Summary'
              break
            case 'pro':
              this.selectedItem = 'Provider Basic Summary'
              break
          }
        }
      },
      showModal(command) {
        this.drilldownParameter = command
        this.otherDashboardVisible = true
      },
      handleTableCommands(command) {
        const visibleComponent = this.otherDashboardVisible ? 'parentComponentOther' : 'parentComponent'
        if (command.ref === 'ipSummary' || command.ref.includes('opSummary_') || command.ref.includes('proSummary_') || command.ref.includes('ascSummary_')) {
          const component = this.$refs[visibleComponent].$refs[command.ref][0] ? this.$refs[visibleComponent].$refs[command.ref][0].$refs[command.ref] : this.$refs[visibleComponent].$refs[command.ref].$refs[command.ref]
          this.tableToCsv(component, `${command.csvName}.csv`, command.headers, command.excludeHeaders)
          return
        }
        if (command.ref.includes('proFinding_')) {
          this.tableToCsv(this.$refs[visibleComponent].$refs[command.ref], `${command.csvName}.csv`, command.headers, command.excludeHeaders)
          return
        }
        const element = this.$refs[visibleComponent].$refs[command.ref]?.$el ? this.$refs[visibleComponent].$refs[command.ref].$el : this.$refs[visibleComponent].$refs[command.ref][0]?.$el ? this.$refs[visibleComponent].$refs[command.ref][0].$el : this.$refs[visibleComponent].$refs[command.ref]
        this.tableToCsv(element, `${command.csvName}.csv`, command.headers, command.excludeHeaders)
      },
      createSpecificReport(payload) {
        const cacheBuster = new Date().getTime()
        let codeType = []
        let codeTypeDesc = payload.row.codeDesc
        console.log('code number - ' & payload.row.codeNumber)
        console.log(payload)
        if (payload.row.codeNumber === -1) {
          this.dropdowns.codeTypes.list.forEach((a) => codeType.push(a.key))
          codeTypeDesc = 'All'
        } else {
          codeType = [payload.row.codeNumber]
        }

        if (payload.row.accountNumbers && this.type === 'pro') {
          window.localStorage.setItem('reportIds', payload.row.accountNumbers)
          window.localStorage.setItem('reportTitle', payload.title)
          window.localStorage.setItem('codeTypes', codeType)
          window.localStorage.setItem('codeTypesDesc', codeTypeDesc)
          this.worksheetWindow = window.open(`/reports/pro-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Pro Audit Report', 'height=850,width=1600')
        }

        if (payload.row.accountNumbers && this.type === 'ip') {
          window.localStorage.setItem('reportIds', payload.row.accountNumbers)
          window.localStorage.setItem('reportTitle', payload.title)
          this.worksheetWindow = window.open(`/reports/inpatient-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Inpatient Audit Report', 'height=850,width=1600')
        }

        if (payload.row.accountNumbers && this.type === 'op') {
          window.localStorage.setItem('reportIds', payload.row.accountNumbers)
          window.localStorage.setItem('reportTitle', payload.title)
          window.localStorage.setItem('codeTypes', codeType)
          window.localStorage.setItem('codeTypesDesc', codeTypeDesc)
          this.worksheetWindow = window.open(`/reports/outpatient-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Outpatient Audit Report', 'height=850,width=1600')
        }

        if (payload.row.accountNumbers && this.type === 'asc') {
          window.localStorage.setItem('reportIds', payload.row.accountNumbers)
          window.localStorage.setItem('reportTitle', payload.title)
          window.localStorage.setItem('codeTypes', codeType)
          window.localStorage.setItem('codeTypesDesc', codeTypeDesc)
          this.worksheetWindow = window.open(`/reports/ambulatory-audit-report?fullscreen=true&dt=${cacheBuster}`, 'Ambulatory Audit Report', 'height=850,width=1600')
        }
      }
    },
    created: function () { },
    updated() {
      // this.groupTeleport()
      // this.searchTeleport()
    },
    computed: {
      dashboardValues() {
        if (this.type) {
          this.defaultSelection()
          const filteredDashboards = this.dashboards.filter(f => this.type === f.type)
          if (!this.isReportSection) {
            filteredDashboards.forEach(a => {
              a.options = a.options.filter(f => (!f.filteredOnly))
            })
          }

          return filteredDashboards
        }
        return this.dashboards
      }
    },
    watch: {
      type() {
        if (this.type && this.type !== this.activatedType) {
          this.defaultSelection()
        }
      }
    }
  }
</script>

<style scoped>
  >>>.caret-wrapper {
    display: none;
  }

  >>>.el-table th>.cell,
  >>>th {
    word-break: break-word;
  }

  >>>.el-table th {
    vertical-align: top;
  }

  >>>.no-data-message {
    font-size: 12px;
    color: #606266;
  }

  >>>#dashboardSelect {
    height: 30px;
  }

  >>>.el-loading-spinner {
    top: 150px !important;
  }

  #drilldownDialog>>>.el-dialog__body {
    overflow: hidden;
  }
</style>