<template>
  <div v-loading="loading || proReportDataLoading" element-loading-spinner="atom-audit-loader" style="min-height: 200px;">
    <div v-for="group in allData" :key="group.key">
      <template v-if="!codeType || group.key === codeType">
        <h2>{{ group.key }}</h2>
        <div style="position: relative; float: right; margin: 0px 0px -10px 0px;">
          <el-dropdown trigger="click" @command="handleTableCommands">
            <span class="el-dropdown-link">
              <i title="table actions" class="mdi mdi-dots-horizontal" style="font-size: 20px; cursor: pointer;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="buildTableCommands('download', `providerTable_${group.key}`)">Download</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-table :id="`providerTable_${group.key}`" :ref="`providerTable_${group.key}`" :data="group.data" empty-text="No Provider Data Available" max-height="425" stripe
          :summary-method="getSummaries" :cell-class-name="cellClasses" show-summary>
          <el-table-column prop="providerName" label="Provider" sortable>
            <template slot-scope="scope">
              <div :title="scope.row.providerName" v-html="highlightValue(scope.row.providerName, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="totalRecords" label="Total Records" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.totalRecords, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="totalCodes" label="Total Codes" sortable>
            <template slot="header">
              Total Codes
              <el-tooltip effect="dark" content="Combined accuracy rate for CM, EM, CPT and Modifier codes" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('Total Codes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.totalCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="cmCodes" label="ICD-10 CM Codes" sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('ICD-10 CM Codes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.cmCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="emCodes" label="E/M Codes" sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('em emCodes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.emCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="cptCodes" label="CPT Codes" sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('cpt cptCodes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.cptCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="modCodes" label="MOD Codes" sortable>
            <template slot-scope="scope">
              <div v-if="checkPercentValues('mod modCodes', scope)">
                N/A
              </div>
              <div v-else v-html="highlightValue(`${(scope.row.modCodes * 100).toFixed(2)}%`, filterCriteria)"></div>
            </template>
          </el-table-column>
          <el-table-column prop="rvuDiff" label="RVU Impact" sortable>
            <template slot-scope="scope">
              <div v-html="highlightValue(scope.row.rvuDiff, filterCriteria)" :class="`${scope.row.rvuDiff < 0 ? 'negative' : ''}`"></div>
            </template>
          </el-table-column>
          <el-table-column prop="details" label="Details" width="150" v-if="!hideDetails">
            <template slot-scope="scope" class="textAlignLeft">
              <el-dropdown size="mini" @command="handleCommand" style="margin-right: 5px;">
                <el-button plain round circle size="mini" icon="mdi mdi-view-dashboard-variant-outline" title="Drilldown"> </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in filteredDashboards" :key="item.name" :command="buildCommand(scope.row, item, group.key)">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button @click.native.prevent="showSummary(scope.row)" plain round size="mini" icon="mdi mdi-table-eye" title="Details" circle> </el-button>
              <el-button style="margin-left: 5px;" @click.native.prevent="updateAcctList(scope.row)" plain round size="mini" icon="mdi mdi-details" title="Records Included"
                circle> </el-button>
              <el-button style="margin-left: 5px;" @click.native.prevent="createSpecificReport(scope.row, group.key)" plain round size="mini" icon="el-icon-document"
                title="Create Report" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <center v-if="!loading">
      <div class="no-data-message" v-if="allData.length === 0">No Provider Data Available</div>
    </center>

    <el-dialog :visible.sync="dialogVisible" center destroy-on-close width="75%">
      <accountList v-if="dialogVisible" :header="detailsHeader" :proRecordIds="subProRecordIds"></accountList>
    </el-dialog>

    <el-dialog :visible.sync="summaryDialogVisible" center destroy-on-close width="75%" title="Provider Basic Summary">
      <PROSummary :ref="`proSummary_${summaryRow.codeDesc} - ${summaryRow.providerName}`" v-if="summaryDialogVisible" :item="summaryRow"
        :title="`${summaryRow.codeDesc} - ${summaryRow.providerName}`" @handleTableCommands="handleTableCommandsModal"></PROSummary>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/utilities/axios-global'
  import hiaFilters from '@/mixins/hiaFilters'
  import money from '@/mixins/money'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import accountList from './accoutList'
  import PROSummary from './PRO_Summary'

  export default {
    name: 'PROByProvider',
    mixins: [hiaFilters, money, dashboardHelper],
    props: ['proRecordIds', 'dashboards', 'hideDetails', 'codeType', 'codeTypes'],
    components: {
      accountList,
      PROSummary
    },
    data: function () {
      return {
        allData: [],
        filterCriteria: '',
        loading: false,
        dialogVisible: false,
        summaryDialogVisible: false,
        subProRecordIds: null,
        summaryRow: {},
        detailsHeader: ''
      }
    },
    methods: {
      async getData() {
        const payload = {
          proRecordIds: this.proRecordIds,
          codeTypes: this.codeTypes,
          includeAccts: true,
          statType: 'test'
        }
        try {
          this.loading = true
          const response = await axios.post(this.baseApiUrl + '/reports/dashboard/proByProvider', payload)
          this.allData = response.data.data
          if (this.codeType === 'All Code Types' && this.allData.length === 1) {
            this.allData[0].key = 'All Code Types'
          }
        } catch (err) {
          this.allData = []
        } finally {
          this.loading = false
        }
      },
      updateAcctList(row) {
        this.detailsHeader = row.providerName
        this.subProRecordIds = row.accountNumbers
        this.dialogVisible = !this.dialogVisible
      },
      showSummary(row) {
        this.summaryRow = row
        this.summaryDialogVisible = !this.summaryDialogVisible
      },
      getSummaries(param) {
        return this.getSummary(param)
      },
      cellClasses({ row, column, rowIndex, columnIndex }) {
        if (column.property === 'providerName' || column.property === 'codeDesc') {
          return 'textAlignLeft'
        }
        if (column.property === 'details') {
          return 'textAlignCenter'
        }
      },
      handleCommand(command) {
        this.$emit('showModal', command)
      },
      buildCommand(outerDashboardRow, selectedDashboard, key) {
        const command = {
          proRecordIds: outerDashboardRow.accountNumbers,
          component: selectedDashboard.component,
          title: outerDashboardRow.providerName + ' - ' + selectedDashboard.name,
          codeType: key
        }
        return command
      },
      handleTableCommands(command) {
        this.$emit('handleTableCommands', { command: command.command, ref: command.key, csvName: `${command.key}_table`, headers: [], excludeHeaders: ['Details'] })
      },
      buildTableCommands(command, key) {
        const commandObject = {
          command: command,
          key: key
        }
        return commandObject
      },
      createSpecificReport(row, key) {
        this.$emit('createSpecificReport', { row: row, title: `${row.providerName} - ${key}` })
      },
      handleTableCommandsModal(command) {
        this.$emit('handleTableCommands', command)
      }
    },
    computed: {
      ...mapState('user/', ['baseApiUrl']),
      ...mapState('reports/', ['proReportDataLoading']),
      filteredDashboards() {
        const proRows = this.dashboards.filter(a => a.type === 'pro')
        let proDashboards = []
        proRows.forEach(x => {
          proDashboards = proDashboards.concat(x.options)
        })
        return proDashboards.filter(a => a.component !== this.$options.name)
      }
    },
    mounted: async function () {
      await this.getData()
    },
    activated: async function () {
      await this.getData()
    },
    watch: {
      proRecordIds: async function () {
        await this.getData()
      },
      codeTypes: async function () {
        await this.getData()
      }
    }
  }
</script>

<style scoped></style>